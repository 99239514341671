import Routes from "Routes";
import ThemeConfig from "theme/index";
import { GlobalStateProvider } from "context/global";
import { DialogProvider } from "context/dialog";

const App = () => {
  return (
    <GlobalStateProvider>
      <ThemeConfig>
        <DialogProvider>
          <Routes />
        </DialogProvider>
      </ThemeConfig>
    </GlobalStateProvider>
  );
};

export default App;
