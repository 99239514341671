import { PropsWithChildren, createContext, useContext, useState } from "react";
import { GlobalState } from "typings/common";
import LogoImg from "assets/ideology_logo.png";
import palette from "theme/palette";

type GlobalStateProps = {
  globalState?: GlobalState;
  //   setGlobalState: (state: GlobalState) => GlobalState;
  setGlobalState: any;
};

const GlobalStateContext = createContext<GlobalStateProps>({
  setGlobalState: (state: GlobalState) => state,
});

export const GlobalStateProvider = ({ children }: PropsWithChildren<{}>) => {
  const [globalState, setGlobalState] = useState<GlobalState>({
    primaryColor: palette.primary.main,
    secondaryColor: palette.secondary.main,
    logo: LogoImg,
  });

  return (
    <GlobalStateContext.Provider value={{ globalState, setGlobalState }}>
      {children}
    </GlobalStateContext.Provider>
  );
};

export const useCurrentState = () => {
  return useContext(GlobalStateContext);
};
