import { useCallback, useEffect, useState } from "react";
import { Card, Chip, Container, Link, Stack } from "@mui/material";
import {
  addSubscribersToChannel,
  approveOrRejectSub,
  deleteSubscriber,
  getAccountsList,
  getSubscribers,
  getTags,
  reSendCustomizationLink,
} from "api/api";

import useQuery from "hooks/useQuery";
import { getUserDataFromToken } from "helpers/auth";
import { IH_SLUG, ROLES } from "enums/common";
import Button from "components/Core/Button";

import { OptionType } from "components/Core/Inputs/MultiSelect";
import Header from "./Header";
import { cleanObject, formatDate, localStorageGetItem } from "helpers/utils";
import AddIcon from "@mui/icons-material/Add";
import { useDialog } from "context/dialog";
import Filters from "./Filters";

import {
  SortingState,
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import RowSelection from "components/Core/Table/RowSelection";
import ChipEllipsis from "components/Core/ChipEllipsis";
import Scrollbar from "components/Core/Scrollbar";
import Table from "components/Core/Table/Table";
import { TagType } from "typings/tag";
import { ColorType } from "typings/common";
import pages from "enums/pages";
import MoreOptions from "components/Core/MoreOptions";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { useCurrentUser } from "context/user";
import { useNavigate } from "react-router";
import { useCurrentState } from "context/global";

const columnHelper = createColumnHelper<any>();

export const columnMeta = (subscriber: any, isIhAccount?: boolean) => {
  // status
  const status = isIhAccount ? subscriber.ih_status : subscriber.status;
  const statusColor: ColorType = ["SUBSCRIBED", "PHANTOM"].includes(status)
    ? "success"
    : status === "REJECTED" || status === "UNSUBSCRIBED"
    ? "error"
    : status === "PENDING"
    ? "info"
    : "default";

  // onboarding
  const onboardingStatus = subscriber?.onboarding_status;
  const onboardingColor: ColorType =
    onboardingStatus === "customization_set"
      ? "primary"
      : subscriber.onboarding_status === "watched_video"
      ? "success"
      : "error";

  return {
    status: {
      label: ["SUBSCRIBED", "PHANTOM"].includes(status)
        ? "Approved"
        : status.toLowerCase(),
      color: statusColor,
    },
    onboarding: {
      label: onboardingStatus?.replace("_", " "),
      color: onboardingColor,
    },
  };
};

const Subscribers = () => {
  const limit = 20;
  const { confirm, showPageLoading, alertError, alertSuccess } = useDialog();
  const navigate = useNavigate();
  const { user } = useCurrentUser();
  const { globalState } = useCurrentState();
  const { params } = useQuery();

  const page = +params.page || 0;
  const channelId = params.channelid;

  const [totalCount, setTotalCount] = useState(0);
  const [subscribers, setSubscribers] = useState<any[]>([]);
  const [triggerRefresh, setTriggerRefresh] = useState(0);

  const [accountOptions, setAccountOptions] = useState<OptionType[]>([]);
  const [tagOptions, setTagOptions] = useState<OptionType[]>([]);

  const {
    account: { slug: accountSlug, id: acId },
  } = getUserDataFromToken();
  const isIhAccount = accountSlug === IH_SLUG;

  const [sorting, setSorting] = useState<SortingState>([]);

  const excludeColFromEdit: any = {
    tags: isIhAccount ? true : false,
    account_name: isIhAccount ? true : false,
  };

  const [columnVisibility, setColumnVisibility] = useState<any>({
    select: channelId ? true : false,
    action: channelId ? false : true,
    tags: isIhAccount ? true : false,
    account_name: isIhAccount ? true : false,
  });

  const handleApproveReject = async (id: string, status: string) => {
    showPageLoading(true);
    try {
      await approveOrRejectSub(id, status);

      alertSuccess("Updated status successfully.");
      setTriggerRefresh((prev: number) => prev + 1);
    } catch (err) {
      console.log(err);
      alertError("Something went wrong! please try again after sometime.");
    }
    showPageLoading(false);
  };

  const reSendCustomization = async (id: string) => {
    showPageLoading(true);
    try {
      await reSendCustomizationLink(id);
      alertSuccess("Customization link sent successfully.");
    } catch (err) {
      console.log(err);
      alertError("Something went wrong! please try again after sometime.");
    }
    showPageLoading(false);
  };

  // table
  const columns: any = [
    {
      id: "select",
      header: ({ table }: any) => (
        <RowSelection
          {...{
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: table.getToggleAllRowsSelectedHandler(),
          }}
        />
      ),
      cell: ({ row }: any) => (
        <RowSelection
          {...{
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      ),
    },
    columnHelper.accessor("first_name", {
      id: "first_name",
      header: "First Name",
      enableSorting: true,
    }),
    columnHelper.accessor("last_name", {
      id: "last_name",
      header: "Last Name",
      enableSorting: true,
    }),
    columnHelper.accessor("email", {
      id: "email",
      header: "Email",
      enableSorting: true,
      cell: ({ row }: any) => {
        const { id, first_name, last_name, account_name, email, medical_designation } =
          row.original;
        const dashboard =
          pages.SUBSCRIBER_DASHBOARD_PAGE.replace(":subscriberId", id) +
          `?name=${
            first_name + " " + last_name
          }&email=${email}&account=${account_name}&md=${medical_designation}`;

        return globalState?.role === ROLES.LITE_CHANNEL_ADMIN ? (
          row.original.email
        ) : (
          <Link href={dashboard} underline="none" color="inherit">
            {row.original.email}
          </Link>
        );
      },
    }),
    columnHelper.accessor("mobile", {
      id: "mobile",
      header: "Mobile",
    }),
    columnHelper.accessor("region", {
      id: "region",
      header: "Region",
    }),
    columnHelper.accessor("zip", {
      id: "zip",
      header: "Zip",
    }),
    columnHelper.accessor("medical_designation", {
      id: "medical_designation",
      header: "Medical Designation",
      enableSorting: true,
    }),
    columnHelper.accessor("tags", {
      id: "tags",
      header: "Tags",
      cell: (info) => <ChipEllipsis items={info.getValue()} />,
    }),
    columnHelper.accessor("account_name", {
      id: "account_name",
      header: "Account",
      enableSorting: true,
    }),
    columnHelper.accessor("status", {
      id: "status",
      header: "Status",
      enableSorting: true,
      cell: ({ row }: any) => {
        const { status } = columnMeta(row.original, isIhAccount);
        return (
          <Chip
            label={status.label}
            color={status.color}
            style={{ textTransform: "capitalize" }}
          />
        );
      },
    }),
    columnHelper.accessor("onboarding", {
      id: "onboarding_status",
      header: "Onboarding",
      enableSorting: true,
      cell: ({ row }: any) => {
        const { onboarding } = columnMeta(row.original);
        return onboarding.label ? (
          <Chip
            label={onboarding.label}
            color={"default"}
            style={{ textTransform: "capitalize" }}
          />
        ) : (
          <></>
        );
      },
    }),
    columnHelper.accessor("registered_from", {
      id: "registered_from",
      header: "Registered From",
    }),
    columnHelper.accessor("created_at", {
      id: "created_at",
      header: "Created Date",
      enableSorting: true,
      cell: (info) => formatDate(info.getValue()),
    }),
    columnHelper.accessor("updated_at", {
      id: "updated_at",
      header: "Updated Date",
      enableSorting: true,
      cell: (info) => formatDate(info.getValue()),
    }),
    columnHelper.accessor("action", {
      id: "action",
      header: "",
      cell: ({ row }: any) => {
        const subscriber = row.original;
        const subscriberLink = `${pages.SUBSCRIBER_PAGE.replace(":id", subscriber.id)}`;
        return (
          <MoreOptions
            options={[
              {
                label: "Edit",
                icon: <EditIcon width={24} height={24} />,
                link: subscriberLink,
              },
              ...(subscriber.status !== "PHANTOM"
                ? [
                    {
                      label: "Approve",
                      icon: <CheckCircleIcon />,
                      onClick: () => handleApproveReject(subscriber.id, "APPROVED"),
                    },
                    {
                      label: "Reject",
                      icon: <CancelIcon />,
                      onClick: () => {
                        handleApproveReject(subscriber.id, "REJECTED");
                      },
                    },
                  ]
                : []),
              ...(subscriber.onboarding_status === "customization_link"
                ? [
                    {
                      label: "Send customization",
                      icon: <InsertLinkIcon />,
                      onClick: () => {
                        reSendCustomization(subscriber.id);
                      },
                    },
                  ]
                : []),
              ...(user?.isSuperAdmin
                ? [
                    {
                      label: "Delete",
                      icon: <DeleteIcon />,
                      onClick: () =>
                        confirmSubscriberDeletion(
                          subscriber.id,
                          subscriber.first_name,
                          subscriber.last_name,
                        ),
                    },
                  ]
                : []),
            ]}
          />
        );
      },
    }),
  ];

  const table = useReactTable({
    data: subscribers,
    columns,
    state: { sorting, columnVisibility },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
  });

  const fetchData = useCallback(async (limit: number, page: number, filter = {}) => {
    try {
      showPageLoading(true);
      const data: any = await getSubscribers(filter, limit, page);
      setSubscribers(data.rows);
      setTotalCount(data.count);
    } catch (err) {
      console.log(err);
    } finally {
      showPageLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchFilterOptions = useCallback(async () => {
    try {
      const tags: any = await getTags(acId, 1000);
      const accounts: any = await getAccountsList();
      setTagOptions([
        ...tags.rows.map((tag: TagType) => ({ label: tag.tag, value: tag.id })),
        { label: "No Tags", value: "no_tags" },
      ]);
      setAccountOptions(
        accounts.accounts.map((a: any) => ({ label: a.name, value: a.id })),
      );
    } catch (error) {
      //
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // save selected videos into channel
  const selectVideosId = table
    .getSelectedRowModel()
    .rows.map(({ original }) => original.id);

  const saveSubscribersToChannels = async () => {
    try {
      if (channelId) {
        showPageLoading(true);
        await addSubscribersToChannel(channelId, selectVideosId);
        navigate(
          pages.CHANNELS_VIDEOS_PAGE.replace(":id", channelId) +
            `?count=${selectVideosId.length}&module=subscriber`,
        );
      }
    } catch (error: any) {
      alertError(error.message);
    }
  };

  const handleDeleteSubscribers = async (id: string) => {
    showPageLoading(true);
    try {
      await deleteSubscriber(id);
      alertSuccess("Deleted subscriber successfully.");
      setTriggerRefresh((prev: number) => prev + 1);
    } catch (err) {
      alertError("Something went wrong!");
    } finally {
      showPageLoading(false);
    }
  };

  const confirmSubscriberDeletion = (id: string, firstName: string, lastName: string) => {
    confirm({
      title: "Delete Subscriber!",
      content: `Are you sure you want to delete "${
        firstName + " " + lastName
      }" subscriber?`,
      cofirmText: "Delete",
      cancelText: "Cancel",
      onConfirm: () => handleDeleteSubscribers(id),
    });
  };

  useEffect(() => {
    fetchFilterOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const queryParams = JSON.stringify(params);
  useEffect(() => {
    const params = JSON.parse(queryParams);
    const search = params.search || "";
    const tag = params.tag ? params.tag.split(",") : [];
    const account = params.account ? params.account.split(",") : [];
    const status = params.status ? params.status.split(",") : [];
    const onboarding = params.onboarding ? params.onboarding.split(",") : [];
    const invitedBy = params.invitedby || "";
    const sort = sorting.length
      ? `${sorting[0].id}:${sorting[0].desc ? "desc" : "asc"}`
      : "";
    const subscriberType = params.subscriberType ? params.subscriberType.split(",") : [];

    const filter: any = {
      search,
      tag,
      account,
      onboarding,
      status,
      invitedBy,
      sort,
      subscriberType,
    };
    fetchData(limit, page, cleanObject(filter));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, page, queryParams, sorting, triggerRefresh]);

  const visibleColums = columns
    .filter((col: any) => col.header && typeof col.header === "string")
    .filter((col: any) =>
      excludeColFromEdit[col.id] !== undefined ? excludeColFromEdit[col.id] : true,
    )
    .map((col: any) => ({ value: col.id, label: col.header }));

  // hide/show columns
  const updateColumnVisibility = useCallback(() => {
    const storedColumns = JSON.parse(
      localStorageGetItem("subscriberTableColumns") || "[]",
    );

    if (storedColumns.length) {
      setColumnVisibility((prev: any) => ({
        ...prev,
        ...Object.fromEntries(storedColumns.map((col: string) => [col, true])),
        ...Object.fromEntries(
          visibleColums
            .filter((col: any) => !storedColumns.includes(col.value))
            .map((col: any) => [col.value, false]),
        ),
      }));
    } else {
      setColumnVisibility((prev: any) => ({
        ...prev,
        ...Object.fromEntries(visibleColums.map((col: any) => [col.value, true])),
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updateColumnVisibility();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container maxWidth={"xl"}>
      <Stack
        mb={2}
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Header />
        {channelId && (
          <Button
            variant="contained"
            onClick={saveSubscribersToChannels}
            startIcon={<AddIcon />}
            // disabled={selectedVideos.length ? false : true}
            sx={{ m: 0 }}
          >
            Add Subscriber To Channel
          </Button>
        )}
      </Stack>

      <Card>
        <Filters
          isIhAccount={isIhAccount}
          accounts={accountOptions}
          tags={tagOptions}
          columns={visibleColums}
          updateColumnVisibility={updateColumnVisibility}
          totalCount={totalCount}
        />

        <Scrollbar>
          <Table table={table} totalCount={totalCount} rowsPerPage={limit} />
        </Scrollbar>
      </Card>
    </Container>
  );
};

export default Subscribers;
