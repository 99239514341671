import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import {
  Chip,
  Container,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";

import Scrollbar from "components/Core/Scrollbar/Scrollbar";
import { subscriberVideoLogs } from "api/api";
import { StyledTableCell } from "components/Core/Table/Table.styles";
import { useDialog } from "context/dialog";
import { formatDate } from "helpers/utils";
import { SMS_STATUS } from "components/Videos/VideoLogs";

interface LogType {
  subscriber_id: string;
  video_id: string;
  video_type: string;
  video_title: string;
  video_link: string;
  sent_at: string;
  watched_at: string;
  status: string;
  twilio_sms_id: string;
}

interface LogResponse {
  logs: {
    rows: LogType[];
    count: number;
  };
}

const TABLE_HEAD = [
  { id: "video", label: "Video" },
  { id: "type", label: "Type" },
  { id: "date_sent", label: "Date Sent" },
  { id: "watched_on", label: "Video Watched On" },
  { id: "status", label: "Twilio Status" },
  { id: "messageId", label: "Message Id" },
];

const VideoLogs = () => {
  const limit = 20;
  const { showPageLoading } = useDialog();
  const { id } = useParams();

  const [logs, setLogs] = useState<LogType[]>([]);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const getData = useCallback(async () => {
    showPageLoading(true);
    try {
      if (id) {
        const res: any = (await subscriberVideoLogs(id, page, limit)) as LogResponse;
        const { rows, count } = res.logs;
        setLogs(rows);
        setTotalCount(count);
      }
    } catch (err) {
      console.log(err);
    } finally {
      showPageLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, limit, page]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <Container maxWidth={"xl"}>
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <TableHead>
              <TableRow>
                {TABLE_HEAD.map((headCell) => (
                  <StyledTableCell key={headCell.id}>{headCell.label}</StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {logs?.map((log: LogType, index) => {
                return (
                  <TableRow hover key={index}>
                    <TableCell>
                      <Link href={log.video_link} target="_blank">
                        {log.video_title}
                      </Link>
                    </TableCell>
                    <TableCell width={110} sx={{ textTransform: "capitalize" }}>
                      {log.video_type.replace("_", " ")}
                    </TableCell>
                    <TableCell>{formatDate(log.sent_at, true)}</TableCell>
                    <TableCell>{formatDate(log.watched_at, true)}</TableCell>
                    <TableCell>
                      <Chip
                        label={log.status}
                        color={SMS_STATUS[log.status]}
                        style={{ textTransform: "capitalize" }}
                      />
                    </TableCell>
                    <TableCell>{log.twilio_sms_id}</TableCell>
                  </TableRow>
                );
              })}
              {!logs.length && (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    <Typography variant="body1">Log not found</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[20]}
          component="div"
          count={totalCount}
          rowsPerPage={limit}
          page={page}
          onPageChange={(_: any, newValue: number) => setPage(newValue)}
        />
      </Scrollbar>
    </Container>
  );
};

export default VideoLogs;
