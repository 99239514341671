import { Box, InputLabel, TextField, TextFieldProps } from "@mui/material";

type InputProps = {
  labelInsideInputField?: boolean;
} & TextFieldProps;

const InputField = ({
  label,
  labelInsideInputField,
  error,
  fullWidth,
  ...rest
}: InputProps) => {
  return (
    <Box sx={{ ...(fullWidth && { width: 1 }) }}>
      {!labelInsideInputField && <InputLabel error={error}>{label}</InputLabel>}
      <TextField
        label={labelInsideInputField ? label : ""}
        error={error}
        fullWidth={fullWidth}
        {...rest}
      />
    </Box>
  );
};

export default InputField;
