import React from "react";
import { useCallback, useEffect, useState } from "react";
import { useFormik } from "formik";
import {
  Box,
  Button,
  Card,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import InputField from "components/Core/Inputs/Input";
import { IconButton, InputAdornment } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useParams } from "react-router-dom";
import { addPoll, deletePoll, getPollsStats, savePollsOrder } from "api/api";
import AddIcon from "@mui/icons-material/Add";
import DownloadIcon from "@mui/icons-material/Download";

import { CSVLink } from "react-csv";
import Select from "components/Core/Inputs/Select";
import Scrollbar from "components/Core/Scrollbar";
import { StyledTableCell } from "components/Core/Table/Table.styles";
import MoreOptions from "components/Core/MoreOptions";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Modal from "components/Core/Modal";
import { v4 as uuid } from "uuid";
import { POLL_TYPES, VIDEO_TYPES } from "enums/dropdown";
import { useDialog } from "context/dialog";
import { formatDate } from "helpers/utils";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { DragDropContext, Draggable, DropResult, Droppable } from "@hello-pangea/dnd";

const Polling = () => {
  const { confirm, alertSuccess, alertError, showPageLoading } = useDialog();
  const { id: videoId } = useParams();

  const [isLoading, setIsLoading] = useState(false);

  const [exportData, setExportData] = useState<any[]>([]);
  const [polls, setPolls] = useState([]);

  const [showModal, setShowModal] = useState(false);

  const [pollId, setPollId] = useState("");

  const TABLE_HEAD = [
    { id: "question", label: "Question" },
    { id: "videoType", label: "Video Type" },
    { id: "createdDate", label: "Created Date" },
    { id: "totalResponses", label: "Total Responses" },
    { id: "action", label: "" },
  ];

  const getData = useCallback(async () => {
    if (!videoId) return;
    showPageLoading(true);
    try {
      const polls: any = await getPollsStats(videoId);
      setPolls(polls);

      setExportData([
        ["Question", "Video Type", "Created Date", "Total Responses"],
        ...polls.map((p: any) => [p.question, p.video_type, p.created_at, p.count]),
      ]);
    } catch (err) {
      console.log(err);
    }
    showPageLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoId]);

  useEffect(() => {
    getData();
  }, [getData]);

  const formik = useFormik({
    initialValues: {
      videoType: "long_video",
      pollType: "single",
      question: "",
      options: [],
    },
    enableReinitialize: true,
    onSubmit: async (values, { resetForm }) => {
      if (!videoId) return;
      setIsLoading(true);
      try {
        await addPoll(pollId, videoId, {
          video_type: values.videoType,
          is_single_answer: values.pollType === "single",
          question: values.question,
          options: values.options,
        });
        alertSuccess("Poll added successfully");
      } catch (err: any) {
        console.log(err);
        alertError(err.message);
      }
      resetForm();
      setIsLoading(false);
      setShowModal(false);
      getData();
    },
  });

  const removeOption = (i: number) => {
    formik.values.options.splice(i, 1);
    formik.setFieldValue("options", formik.values.options);
  };

  const handleDeletePoll = async (videoId: string, pollId: string) => {
    showPageLoading(true);
    try {
      await deletePoll(pollId, videoId);
      alertSuccess("Poll deleted successfully.");
      getData();
    } catch (err: any) {
      alertError(err.message);
    }
    showPageLoading(false);
  };

  const deletePollConfirmation = (videoId: string, pollId: string, question: string) => {
    confirm({
      title: "Delete Poll!",
      content: `Are you sure you want to delete "${question}" poll?`,
      cofirmText: "Delete",
      cancelText: "Cancel",
      onConfirm: () => handleDeletePoll(videoId, pollId),
    });
  };

  return (
    <Container style={{ padding: 0 }} maxWidth={"xl"}>
      <Modal
        open={showModal}
        onClose={() => {
          formik.resetForm();
          setShowModal(false);
        }}
      >
        <Typography variant="h4" gutterBottom align="center">
          Poll
        </Typography>

        <Box mt={2} component="form" onSubmit={formik.handleSubmit}>
          <Stack gap={2}>
            <Select
              id="videoType"
              label="Video Type"
              name="videoType"
              options={VIDEO_TYPES}
              value={formik.values.videoType}
              onChange={(val: string) => {
                formik.setFieldValue("videoType", val);
              }}
              error={formik.touched.videoType && Boolean(formik.errors.videoType)}
            />

            <Select
              id="pollType"
              label="Poll Type"
              name="pollType"
              options={POLL_TYPES}
              value={formik.values.pollType}
              onChange={(val: string) => {
                formik.setFieldValue("pollType", val);
              }}
              error={formik.touched.pollType && Boolean(formik.errors.pollType)}
            />

            <InputField
              label="Question"
              id="question"
              name="question"
              value={formik.values.question}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.question && Boolean(formik.errors.question)}
              helperText={formik.touched.question && formik.errors.question}
              fullWidth
              labelInsideInputField
            />

            <Stack direction="row" my={2} spacing={10} justifyContent="space-between">
              <Typography variant="h6" gutterBottom>
                Options
              </Typography>
              <Button
                variant="contained"
                onClick={() => {
                  formik.setFieldValue("options", [...formik.values.options, undefined]);
                }}
                startIcon={<AddIcon />}
              >
                Add Option
              </Button>
            </Stack>

            {formik.values.options.map((i: number, index: number) => (
              <InputField
                key={index}
                id={`options${index}`}
                name="options"
                value={formik.values.options[index]}
                onChange={(e) => {
                  const options: string[] = formik.values.options;
                  options[index] = e.target.value;
                  formik.setFieldValue("options", options);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => removeOption(index)} edge="end">
                        <CloseIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            ))}

            <LoadingButton
              type="submit"
              size="large"
              variant="contained"
              loading={isLoading}
            >
              Save Poll
            </LoadingButton>
          </Stack>
        </Box>
      </Modal>

      <Stack direction="row" mb={2} spacing={2} justifyContent={"end"}>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => {
            setPollId(uuid());
            setShowModal(true);
          }}
        >
          Add Poll
        </Button>

        {exportData.length !== 0 && (
          <CSVLink
            data={exportData}
            filename={"polling-results.csv"}
            style={{ textDecoration: "none" }}
          >
            <LoadingButton variant="contained" startIcon={<DownloadIcon />}>
              Export
            </LoadingButton>
          </CSVLink>
        )}
      </Stack>

      <Card>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <DragDropContext
              onDragEnd={async ({ destination, source }: DropResult) => {
                if (!destination) return;

                const startIndex = source.index - 1;
                const endIndex = destination.index;

                const result = polls;
                const [removed] = result.splice(startIndex, 1);
                result.splice(endIndex, 0, removed);

                setPolls(result);

                const pollsOrder = result.map((poll: any, index) => ({
                  id: poll.id,
                  order: index,
                }));
                await savePollsOrder(videoId ?? "", pollsOrder);
              }}
            >
              <Droppable droppableId="droppable-list">
                {(provided) => (
                  <Table ref={provided.innerRef} {...provided.droppableProps}>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell></StyledTableCell>
                        {TABLE_HEAD.map((headCell) => (
                          <StyledTableCell key={headCell.id}>
                            {headCell.label}
                          </StyledTableCell>
                        ))}
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {polls.map((row: any) => {
                        const {
                          id,
                          question,
                          options,
                          video_type,
                          created_at,
                          count,
                          is_single_answer,
                        } = row;

                        return (
                          <Draggable key={id} draggableId={id} index={1}>
                            {(provided) => (
                              <TableRow
                                hover
                                tabIndex={-1}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                ref={provided.innerRef}
                              >
                                <TableCell>
                                  <DragIndicatorIcon />
                                </TableCell>
                                <TableCell>{question}</TableCell>
                                <TableCell>
                                  {video_type === "short_video" ? "Short" : "Long"}
                                </TableCell>
                                <TableCell>{formatDate(created_at)}</TableCell>
                                <TableCell>{count}</TableCell>
                                <TableCell align="right">
                                  <MoreOptions
                                    options={[
                                      {
                                        label: "Edit",
                                        icon: <EditIcon width={24} height={24} />,
                                        onClick: () => {
                                          setPollId(id);
                                          formik.setFieldValue("question", question);
                                          formik.setFieldValue("videoType", video_type);
                                          formik.setFieldValue("options", options);
                                          formik.setFieldValue(
                                            "pollType",
                                            is_single_answer ? "single" : "multiple",
                                          );
                                          setShowModal(true);
                                        },
                                      },
                                      {
                                        label: "Delete",
                                        icon: <DeleteIcon width={24} height={24} />,
                                        onClick: () =>
                                          deletePollConfirmation(
                                            videoId || "",
                                            id,
                                            question,
                                          ),
                                      },
                                    ]}
                                  />
                                </TableCell>
                              </TableRow>
                            )}
                          </Draggable>
                        );
                      })}
                    </TableBody>
                  </Table>
                )}
              </Droppable>
            </DragDropContext>
          </TableContainer>
        </Scrollbar>
      </Card>
    </Container>
  );
};

export default Polling;
