import { useEffect, useState } from "react";
import useQuery from "hooks/useQuery";
import { resendCode } from "helpers/auth";
import { useNavigate } from "react-router-dom";
import pages from "enums/pages";
import { useParams } from "react-router-dom";

import AlertTitle from "@mui/material/AlertTitle";
import { LoadingButton } from "@mui/lab";
import Box from "@mui/material/Box";

import { confirmAccount } from "api/api";
import { CircularProgress, Container, CssBaseline, Typography } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { CombinedLogo } from "components/Core/Logo/Logo";
import { useDialog } from "context/dialog";

const ConfirmUser = () => {
  const { alertSuccess, alertError } = useDialog();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [error, setError] = useState<Error>();

  const [resendCodeLoading, setResendCodeLoading] = useState(false);

  const { params } = useQuery();
  const { code, username, type } = params;

  const { slug = "admin" } = useParams();
  const navigate = useNavigate();

  const loginPage = pages.LOGIN_PAGE.replace(":slug", slug);

  const redirectToLogin = () => {
    return navigate(loginPage, { replace: true });
  };

  useEffect(() => {
    if (code && username && type) {
      confirmAccount(username, code, type)
        .then(() => {
          setTimeout(() => {
            redirectToLogin();
          }, 2000);
        })
        .catch((err: any) => {
          if (err.message.includes("EmailSentSuccessfully")) {
            setTimeout(() => {
              redirectToLogin();
            }, 2000);
          } else {
            setError(err);
          }
        })
        .finally(() => setIsPageLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, username, type]);

  const resendConfirmationEmail = () => {
    if (username) {
      setResendCodeLoading(true);
      resendCode(username)
        .then(() => alertSuccess("Confirmation email sent successfully."))
        .catch((err: any) => {
          if (err.message.includes("EmailSentSuccessfully")) {
            alertSuccess("Confirmation email sent successfully.");
          } else {
            alertError(err.message);
          }
        })
        .finally(() => setResendCodeLoading(false));
    }
  };

  return (
    <Container>
      <CssBaseline />

      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <CombinedLogo />

        {isPageLoading ? (
          <Box mt={5}>
            <CircularProgress color="success" size={"40px"} />
          </Box>
        ) : error ? (
          <Box p={4} textAlign={"center"}>
            <ErrorOutlineIcon color="error" sx={{ fontSize: "80px" }} />
            <AlertTitle>Error</AlertTitle>
            <Typography variant="subtitle1">{error.message}</Typography>
            <Box sx={{ mt: 2 }}>
              <LoadingButton
                size="large"
                variant="contained"
                loading={resendCodeLoading}
                onClick={resendConfirmationEmail}
                fullWidth
              >
                Resend confirmation email
              </LoadingButton>
            </Box>
          </Box>
        ) : (
          <Box p={4} textAlign={"center"}>
            <CheckCircleOutlineIcon color="success" sx={{ fontSize: "80px" }} />
            <Box my={2}>
              <AlertTitle>Account confirm successfully</AlertTitle>
            </Box>

            <Box sx={{ mt: 2 }}>
              <LoadingButton
                size="large"
                variant="contained"
                onClick={redirectToLogin}
                fullWidth
              >
                Login
              </LoadingButton>
            </Box>
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default ConfirmUser;
