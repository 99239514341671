import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  registerables as registerablesJS,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import { useCurrentState } from "context/global";

ChartJS.register(...registerablesJS);
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
);

type Props = {
  labels: string[];
  data: number[];
  width?: string;
  height?: string;
};

const TopVideosChart = ({ labels, data, width, height }: Props) => {
  const { globalState } = useCurrentState();
  return (
    <Chart
      type="bar"
      width={width}
      height={height}
      options={{
        indexAxis: "x",
        responsive: true,
        scales: {
          x: {
            grid: {
              drawBorder: false,
              display: false,
            },
          },
          y: {
            grid: {
              drawBorder: false,
              display: false,
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
      }}
      data={{
        labels: labels,
        datasets: [
          {
            type: "bar",
            label: "Views",
            data: data,
            borderColor: globalState?.primaryColor,
            backgroundColor: globalState?.primaryColor,
            barThickness: 14,
            borderRadius: 14,
          },
        ],
      }}
    />
  );
};

export default TopVideosChart;
