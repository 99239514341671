export default {
  HOME_PAGE: "/",
  SLUG: "/:slug",

  // Public
  ADMIN_LOGIN_PAGE: "/login",
  LOGIN_PAGE: "/:slug/login",
  SIGNUP_PAGE: "/:slug/signup",
  FORGOT_PASSWORD_PAGE: "/:slug/forgot-password",
  CONFIRM_ACCOUNT_PAGE: "/:slug/confirm",

  // Protected
  DASHBOARD_PAGE: "/dashboard",
  SETTINGS_PAGE: "/settings",
  VIDEOS_REPORTS_PAGE: "/reports/videos",
  SUBSCRIBERS_REPORTS_PAGE: "/reports/subscribers",

  VIDEOS_LIBRARY_PAGE: "/videos",
  VIDEOS_PAGE: "/video/:id",

  CHANNELS_PAGE: "/channels",
  CHANNELS_VIDEOS_PAGE: "/channels/:id/videos",

  USERS_PAGE: "/users",
  PROFILE_PAGE: "/profile",

  SUBSCRIBERS_PAGE: "/subscribers",
  SUBSCRIBER_PAGE: "/subscribers/:id",

  SUBSCRIBER_DASHBOARD_PAGE: "/subscriber/dashboard/:subscriberId",
  VIDEO_DASHBOARD_PAGE: "/video/dashboard/:videoId",

  MESSAGES_PAGE: "/messages",

  ADMIN_DASHBOARD_PAGE: "/admin/dashboard",
  ADMIN_ACCOUNTS_PAGE: "/admin/accounts",
  ADMIN_ADD_ACCOUNT_PAGE: "/admin/account/add",
  ADMIN_MESSAGES_PAGE: "/admin/messages",

  BUSINESS_DASHBOARD_PAGE: "/businessdashboard",

  OTHER_CLINICS: "/clinics/other",

  PAGE_403: "/403",
};
