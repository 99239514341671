import { useState } from "react";
import { Button, Stack, Typography } from "@mui/material";
import Modal from "components/Core/Modal";
import AddIcon from "@mui/icons-material/Add";
import { inviteUser } from "api/api";
import { LoadingButton } from "@mui/lab";
import Box from "@mui/material/Box";
import { useFormik } from "formik";
import InputField from "components/Core/Inputs/Input";
import VALIDATION from "helpers/validation_schemas";
import Select from "components/Core/Inputs/Select";
import { ROLES } from "enums/common";
import { useDialog } from "context/dialog";

const ROLE_OPTIONS = Object.values(ROLES).map((role) => ({
  label: (
    <Typography style={{ textTransform: "capitalize" }}>
      {role.replaceAll("_", " ")}
    </Typography>
  ),
  value: role,
}));

const InviteUser = () => {
  const { alertSuccess, alertError } = useDialog();
  const [showInviteUserModal, setShowInviteUserModal] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
      role: "",
    },
    validationSchema: VALIDATION.user_invite,
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      try {
        await inviteUser(values.email, values.role);
        alertSuccess("Invite sent successfully");
      } catch (err) {
        if (err instanceof Error) {
          console.log(err);
          alertError(err.message);
        }
      } finally {
        setIsLoading(false);
      }
      resetForm();
      setShowInviteUserModal(false);
    },
  });

  return (
    <>
      <Button
        variant="contained"
        onClick={() => setShowInviteUserModal(true)}
        startIcon={<AddIcon />}
        size="large"
      >
        Invite User
      </Button>
      <Modal
        open={showInviteUserModal}
        onClose={() => {
          setShowInviteUserModal(false);
        }}
      >
        <Typography variant="h4" gutterBottom align="center">
          Invite User
        </Typography>

        <Box component="form" onSubmit={formik.handleSubmit}>
          <Stack gap={2}>
            <InputField
              label="Email"
              id="email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              autoFocus
              fullWidth
              labelInsideInputField
            />

            <Select
              id="role"
              label="Role"
              name="role"
              options={ROLE_OPTIONS}
              value={formik.values.role}
              onChange={(val: string) => formik.setFieldValue("role", val)}
              error={formik.touched.role && Boolean(formik.errors.role)}
              helperText={formik.touched.role && formik.errors.role}
            />

            <LoadingButton
              fullWidth
              size="large"
              variant="contained"
              loading={isLoading}
              type="submit"
            >
              Invite
            </LoadingButton>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

export default InviteUser;
