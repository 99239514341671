import React, { useEffect, useState } from "react";
import AddEditVideo from "./AddEditVideo";
import Polling from "./Polling";
import Test from "./Test";
import VideoSettingsIcon from "@mui/icons-material/VideoSettings";
import PollIcon from "@mui/icons-material/Poll";
import ViewListIcon from "@mui/icons-material/ViewList";
import { Tab, TabContext, TabList, TabPanel } from "components/Core/Tab/Tab";
import { Container, Typography } from "@mui/material";
import BiotechIcon from "@mui/icons-material/Biotech";
import useQuery from "hooks/useQuery";
import VideoLogs from "./VideoLogs";
import { useCurrentState } from "context/global";
import { ROLES } from "enums/common";

const Settings = () => {
  const { params, hash, updateUri } = useQuery();
  const isNew = params.new === "true";

  const { globalState } = useCurrentState();
  const role = globalState?.role;

  const [tabValue, setTabValue] = useState("1");
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    let hashVal = "";
    switch (newValue) {
      case "2":
        hashVal = "#polling";
        break;
      case "3":
        hashVal = "#videologs";
        break;
      case "4":
        hashVal = "#test";
        break;
    }
    updateUri(hashVal);
    setTabValue(newValue);
  };

  useEffect(() => {
    switch (hash) {
      case "#polling":
        setTabValue("2");
        break;
      case "#videologs":
        setTabValue("3");
        break;
      case "#test":
        setTabValue("4");
        break;
      default:
        setTabValue("1");
        break;
    }
  }, [hash]);

  return (
    <Container maxWidth={"xl"}>
      <Typography variant="h4" mb={5}>
        Video
      </Typography>
      <TabContext value={tabValue}>
        <TabList onChange={handleChange}>
          <Tab
            iconPosition="start"
            label="Video"
            icon={<VideoSettingsIcon />}
            value="1"
          />
          {!isNew && (
            <Tab
              iconPosition="start"
              label="User Polling"
              icon={<PollIcon />}
              value="2"
            />
          )}

          {!isNew && role !== ROLES.LITE_CHANNEL_ADMIN && (
            <Tab
              iconPosition="start"
              label="Video Logs"
              icon={<ViewListIcon />}
              value="3"
            />
          )}

          {!isNew && (
            <Tab iconPosition="start" label="Test" icon={<BiotechIcon />} value="4" />
          )}
        </TabList>
        <TabPanel value="1">
          <AddEditVideo />
        </TabPanel>
        <TabPanel value="2">
          <Polling />
        </TabPanel>
        {role !== ROLES.LITE_CHANNEL_ADMIN && (
          <TabPanel value="3">
            <VideoLogs />
          </TabPanel>
        )}
        <TabPanel value="4">
          <Test />
        </TabPanel>
      </TabContext>
    </Container>
  );
};

export default Settings;
