import { Box, Chip, Tooltip } from "@mui/material";

type Props = {
  width?: string;
  items: string[];
};

const ChipEllipsis = ({ width, items }: Props) => {
  return items.length ? (
    <Tooltip title={items.join(", ")}>
      <Box
        sx={{
          width: width || "200px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitLineClamp: 2,
          WebkitBoxOrient: "vertical",
        }}
      >
        {items?.map((item, index) =>
          item ? <Chip key={index} label={item} sx={{ mr: "2px", mb: "2px" }} /> : "-",
        )}
      </Box>
    </Tooltip>
  ) : (
    <></>
  );
};

export default ChipEllipsis;
