import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import SettingsIcon from "@mui/icons-material/Settings";
import GroupIcon from "@mui/icons-material/Group";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import DashboardIcon from "@mui/icons-material/Dashboard";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import MessageIcon from "@mui/icons-material/Message";
import { ItemProps } from "typings/Menu";
import pages from "enums/pages";
import { ROLES } from "enums/common";
import { useCurrentState } from "context/global";
import { useCurrentUser } from "context/user";
import InsertChartIcon from "@mui/icons-material/InsertChart";

type OptionType = {
  [key: string]: ItemProps;
};

const options: OptionType = {
  dashboard: {
    title: "Dashboard",
    path: pages.DASHBOARD_PAGE,
    icon: <DashboardIcon />,
  },
  channels: {
    title: "Channels",
    path: pages.CHANNELS_PAGE,
    icon: <WorkspacesIcon />,
  },
  videos: {
    title: "Video Library",
    path: pages.VIDEOS_LIBRARY_PAGE,
    icon: <VideoLibraryIcon />,
  },
  subscribers: {
    title: "Subscribers",
    path: pages.SUBSCRIBERS_PAGE,
    icon: <AccountBoxIcon />,
  },
  users: { title: "Users", path: pages.USERS_PAGE, icon: <GroupIcon /> },
  messages: {
    title: "Message",
    path: pages.MESSAGES_PAGE,
    icon: <MessageIcon />,
  },
  reports: {
    title: "Reports",
    path: pages.VIDEOS_REPORTS_PAGE,
    icon: <InsertChartIcon />,
    children: [
      { title: "videos", path: pages.VIDEOS_REPORTS_PAGE },
      { title: "Subscribers", path: pages.SUBSCRIBERS_REPORTS_PAGE },
    ],
  },
  settings: {
    title: "Settings",
    path: pages.SETTINGS_PAGE,
    icon: <SettingsIcon />,
  },
};

const MenuOptions = () => {
  const { globalState } = useCurrentState();
  const { user } = useCurrentUser();

  const isSuperAdmin = !!user?.isSuperAdmin;
  const role = globalState?.role;

  const menuOptions: ItemProps[] = [];

  if ((user?.channels?.length || 0) > 1) {
    options.channels.children = user?.channels.map((channel) => ({
      title: channel.name,
      path: pages.CHANNELS_VIDEOS_PAGE.replace(":id", channel.id),
    }));
  }

  if (role === ROLES.LITE_CHANNEL_ADMIN) {
    menuOptions.push(...[options.channels, options.videos, options.reports]);
  }

  if (role === ROLES.CHANNEL_ADMIN) {
    menuOptions.push(
      ...[options.dashboard, options.channels, options.videos, options.reports],
    );
  }

  if (isSuperAdmin || role === ROLES.ACCOUNT_ADMIN) {
    menuOptions.push(...Object.values(options));
  }

  return menuOptions;
};

export default MenuOptions;
