import { useEffect, useState } from "react";
import { useFormik } from "formik";

import { Box, Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import Modal from "components/Core/Modal";
import InputField from "components/Core/Inputs/Input";
import VALIDATION from "helpers/validation_schemas";
import { ClinicType } from "typings/clinic";
import { toSlug } from "helpers/utils";
import { createAccount } from "api/api";
import { useCurrentUser } from "context/user";
import { useDialog } from "context/dialog";

type Props = {
  openModal: boolean;
  setOpenModal: (x: boolean) => void;
  clinic: ClinicType;
  refreshList: () => void;
};

const CreateAccount = ({ clinic, openModal, setOpenModal, refreshList }: Props) => {
  const { alertSuccess, alertError } = useDialog();
  const [isLoading, setIsLoading] = useState(false);

  const { user } = useCurrentUser();

  const formik = useFormik({
    initialValues: {
      companyName: clinic.name,
      slug: toSlug(clinic.name),
      email: user?.email,
    },
    enableReinitialize: true,
    validationSchema: VALIDATION.add_account,
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      try {
        await createAccount({
          id: clinic.id,
          name: values.companyName,
          slug: values.slug,
          email: values.email || "",
        });

        alertSuccess("Account created successfully");

        refreshList();
        setOpenModal(false);
      } catch (err: any) {
        alertError(err.message);
      }
      setIsLoading(false);
      resetForm();
    },
  });

  useEffect(() => {
    if (!openModal) {
      formik.resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal]);

  return (
    <>
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Typography variant="h4" gutterBottom>
          Create Account
        </Typography>
        <Box component="form" onSubmit={formik.handleSubmit}>
          <Stack gap={2}>
            <InputField
              label="Account Name"
              id="companyName"
              name="companyName"
              value={formik.values.companyName}
              onChange={(e) => {
                formik.handleChange(e);
                formik.setFieldValue("slug", toSlug(e.target.value));
              }}
              onBlur={formik.handleBlur}
              error={formik.touched.companyName && Boolean(formik.errors.companyName)}
              helperText={formik.touched.companyName && formik.errors.companyName}
              fullWidth
              labelInsideInputField
            />

            <InputField
              label="Slug"
              id="slug"
              name="slug"
              value={formik.values.slug}
              onChange={formik.handleChange}
              onBlur={(e) => {
                formik.handleBlur(e);
                formik.setFieldValue("slug", toSlug(e.target.value));
              }}
              error={formik.touched.slug && Boolean(formik.errors.slug)}
              helperText={formik.touched.slug && formik.errors.slug}
              fullWidth
              labelInsideInputField
            />

            <InputField
              label="Email"
              id="email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              fullWidth
              labelInsideInputField
            />

            <LoadingButton
              fullWidth
              type="submit"
              size="large"
              variant="contained"
              loading={isLoading}
            >
              Create Account
            </LoadingButton>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

export default CreateAccount;
