import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { SxProps, Theme } from "@mui/material";

type Props = {
  title: string;
  errors?: string[];
  sx?: SxProps<Theme>;
};

const Error = ({ title, errors, sx }: Props) => {
  return (
    <Alert severity="error" sx={{ margin: "24px 0", ...sx }}>
      {title && <AlertTitle>{title}</AlertTitle>}
      {errors && errors.map((m: string, index: number) => <li key={index}>{m}</li>)}
    </Alert>
  );
};

export default Error;
