import { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { useFormik } from "formik";

import { Box, Button, Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import AddIcon from "@mui/icons-material/Add";

import Modal from "components/Core/Modal";
import InputField from "components/Core/Inputs/Input";
import VALIDATION from "helpers/validation_schemas";
import { ChannelType } from "typings/channel";
import { upsertChannel } from "api/api";
import { refreshToken } from "helpers/auth";
import { useDialog } from "context/dialog";

type Props = {
  openModal: boolean;
  setOpenModal: (x: boolean) => void;
  channel?: ChannelType;
  refreshList: () => void;
};

const AddUpdateChannel = ({ channel, openModal, setOpenModal }: Props) => {
  const { alertSuccess, alertError } = useDialog();
  const [isNew, setIsNew] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const initValues: ChannelType = {
    id: "",
    name: "",
    description: "",
  };
  const [initialValues, setInitialValues] = useState<ChannelType>(initValues);

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: VALIDATION.channel_schema,
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      try {
        await upsertChannel(values.id || uuid(), {
          name: values.name,
          description: values.description,
        });

        alertSuccess("Channel added successfully.");
        setOpenModal(false);
        refreshToken(); // refresh session
      } catch (err: any) {
        alertError(err.message);
      }
      setIsLoading(false);
      resetForm();
    },
  });

  useEffect(() => {
    if (isNew || !channel) {
      setInitialValues(initValues);
    } else if (channel) {
      setInitialValues({
        id: channel.id,
        name: channel.name,
        description: channel.description || "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel, isNew]);

  useEffect(() => {
    if (!openModal) {
      formik.resetForm();
      setIsNew(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal]);

  return (
    <>
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Typography variant="h4" gutterBottom align="center">
          Channel
        </Typography>

        <Box component="form" onSubmit={formik.handleSubmit}>
          <Stack gap={2}>
            <InputField
              id="name"
              name="name"
              label="Channel Name"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              fullWidth
              labelInsideInputField
            />

            <InputField
              id="description"
              name="description"
              label="Description"
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.description && Boolean(formik.errors.description)}
              helperText={formik.touched.description && formik.errors.description}
              fullWidth
              labelInsideInputField
              multiline
              rows={4}
            />

            <LoadingButton
              fullWidth
              type="submit"
              size="large"
              variant="contained"
              loading={isLoading}
            >
              Save
            </LoadingButton>
          </Stack>
        </Box>
      </Modal>

      <Box p={2} textAlign={"right"}>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => {
            setIsNew(true);
            setOpenModal(true);
          }}
          size="large"
        >
          New Channel
        </Button>
      </Box>
    </>
  );
};

export default AddUpdateChannel;
