import {
  Card,
  CardContent,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import { getApiKey, reCreateApiKey } from "api/api";
import InputField from "components/Core/Inputs/Input";
import { useDialog } from "context/dialog";
import { useCallback, useEffect, useMemo, useState } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import RefreshIcon from "@mui/icons-material/Refresh";
import { LoadingButton } from "@mui/lab";

const Api = () => {
  const { showPageLoading, alertSuccess, alertError } = useDialog();

  const [key, setKey] = useState<any>({});
  const [btnLoading, setBtnLoading] = useState(false);

  const fetchApiKey = useCallback(async () => {
    try {
      showPageLoading(true);
      const data: any = await getApiKey();
      setKey(data.key);
    } catch (err) {
      console.log(err);
    } finally {
      showPageLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchApiKey();
  }, [fetchApiKey]);

  //
  const maskKey = useMemo(() => {
    if (key?.value) {
      const actualKey = key.value;
      const keyLength = actualKey.length;
      const firstFive = actualKey.slice(0, 5);
      const lastFive = actualKey.slice(-5);
      return (
        firstFive +
        Array(keyLength - 10)
          .fill("*")
          .join("") +
        lastFive
      );
    }
  }, [key]);

  const copyKey = async () => {
    try {
      await navigator.clipboard.writeText(key.value);
      alertSuccess("Copied!");
    } catch (err) {
      //
    }
  };

  const regenerateKey = async () => {
    try {
      setBtnLoading(true);
      const res: any = await reCreateApiKey();
      setKey(res.key);
      alertSuccess("New key created successfully.");
    } catch (err) {
      console.log(err);
      alertError("Something went wrong, please try again!");
    } finally {
      setBtnLoading(false);
    }
  };

  return (
    <Card>
      <CardContent>
        <Stack direction={"row"} gap={2} alignItems={"center"}>
          <Typography whiteSpace={"nowrap"}>Api Key:</Typography>
          <InputField
            value={maskKey}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton onClick={copyKey}>
                    <ContentCopyIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            fullWidth
          />
          <LoadingButton
            size="large"
            variant="contained"
            sx={{ whiteSpace: "nowrap" }}
            startIcon={<RefreshIcon />}
            loading={btnLoading}
            onClick={regenerateKey}
          >
            Re-Create
          </LoadingButton>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default Api;
