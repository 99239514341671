import { MouseEvent, useState } from "react";
import { NavLink as RouterLink, matchPath, useLocation } from "react-router-dom";
import { alpha, styled, useTheme } from "@mui/material/styles";
import {
  Box,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import { ItemProps, MenuItemProps, MenuProps } from "typings/Menu";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import PopoverMenu from "@mui/material/Menu";

const ListItemStyle = styled((props) => <ListItemButton disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.body2,
    height: 48,
    position: "relative",
    textTransform: "capitalize",
    paddingLeft: theme.spacing(3.5),
    paddingRight: theme.spacing(2.5),
    color: theme.palette.text.secondary,
    "&:before": {
      top: 0,
      right: 0,
      width: 2,
      bottom: 0,
      content: "''",
      display: "none",
      position: "absolute",
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
      backgroundColor: theme.palette.primary.main,
    },
  }),
);

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const activeSubStyle = {
  color: "text.primary",
  fontWeight: "fontWeightMedium",
};

const MenuSubOptions = ({
  options,
  active,
  callback,
}: {
  options: ItemProps[];
  active: (path: string) => boolean;
  callback?: () => void;
}) => {
  return (
    <List component="div" disablePadding>
      {options.map((item) => {
        const { title, path } = item;
        const isActiveSub = active(path);

        return (
          <ListItemStyle
            key={title}
            component={RouterLink}
            to={path}
            sx={{
              ...(isActiveSub && activeSubStyle),
            }}
            onClick={callback}
          >
            <ListItemIconStyle>
              <Box
                component="span"
                sx={{
                  width: 4,
                  height: 4,
                  display: "flex",
                  borderRadius: "50%",
                  alignItems: "center",
                  justifyContent: "center",
                  bgcolor: "text.disabled",
                  transition: (theme) => theme.transitions.create("transform"),
                  ...(isActiveSub && {
                    transform: "scale(2)",
                    bgcolor: "primary.main",
                  }),
                }}
              />
            </ListItemIconStyle>
            <ListItemText disableTypography primary={title} />
          </ListItemStyle>
        );
      })}
    </List>
  );
};

const MenuItem = ({ item, active, miniDrawer }: MenuItemProps) => {
  const theme = useTheme();
  const isActiveRoot = active(item.path);
  const { title, path, icon, info, children } = item;
  const [open, setOpen] = useState(isActiveRoot);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const popoverMenuClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closePopoverMenu = () => {
    setAnchorEl(null);
  };

  const activeRootStyle = {
    color: "primary.main",
    fontWeight: "fontWeightMedium",
    bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
    "&:before": { display: "block" },
  };

  if (children) {
    return miniDrawer ? (
      <>
        <ListItemStyle
          sx={{
            ...(isActiveRoot && activeRootStyle),
          }}
          onClick={popoverMenuClick}
        >
          <Tooltip title={title}>
            <ListItemIconStyle sx={{ marginRight: 1 }}>{icon && icon}</ListItemIconStyle>
          </Tooltip>
          <KeyboardArrowRightIcon />
        </ListItemStyle>

        <PopoverMenu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={closePopoverMenu}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <MenuSubOptions
            options={children}
            active={active}
            callback={closePopoverMenu}
          />
        </PopoverMenu>
      </>
    ) : (
      <>
        <ListItemStyle
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot && activeRootStyle),
          }}
        >
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
          <ListItemText disableTypography primary={title} />
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          {info && info}
        </ListItemStyle>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <MenuSubOptions options={children} active={active} />
        </Collapse>
      </>
    );
  }

  return (
    <ListItemStyle
      component={RouterLink}
      to={path}
      sx={{
        ...(isActiveRoot && activeRootStyle),
      }}
    >
      {miniDrawer ? (
        <Tooltip title={title}>
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
        </Tooltip>
      ) : (
        <>
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
          <ListItemText disableTypography primary={title} />
        </>
      )}
      {info && info}
    </ListItemStyle>
  );
};

const Menu = ({ navConfig, miniDrawer, ...other }: MenuProps) => {
  const { pathname } = useLocation();
  const match = (path: string) =>
    path ? !!matchPath({ path, end: false }, pathname) : false;

  return (
    <Box {...other}>
      <List disablePadding>
        {navConfig.map((item) => (
          <MenuItem key={item.title} item={item} active={match} miniDrawer={miniDrawer} />
        ))}
      </List>
    </Box>
  );
};

export default Menu;
