import { Card, CardContent, Stack, Typography } from "@mui/material";

import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  registerables as registerablesJS,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useCurrentState } from "context/global";
import { lineBreak } from "helpers/utils";

ChartJS.register(...registerablesJS);
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
);

type Props = {
  labels: string[];
  data: number[];
  labelsLink?: string[];
  width?: string;
  height?: string;
};

const TopVideosChart = ({ labels, data, labelsLink, width, height }: Props) => {
  const { globalState } = useCurrentState();

  return (
    <Card>
      <CardContent>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent={"space-between"}
          mb={2}
          spacing={1}
        >
          <Typography fontSize={18} mb={3}>
            Top Viewed Videos
          </Typography>
        </Stack>

        <Bar
          width={width}
          height={height}
          options={{
            indexAxis: "y",
            responsive: true,
            scales: {
              x: {
                grid: {
                  drawBorder: false,
                  display: false,
                },
                ticks: {
                  display: false,
                },
              },
              y: {
                grid: {
                  drawBorder: false,
                  display: false,
                },
                ticks: {
                  labelOffset: -20,
                  mirror: true,
                  callback: function (this, value, index) {
                    const label = this.getLabelForValue(index);
                    return label && label.length > 60
                      ? lineBreak(label, 50)[0] + " ..."
                      : label;
                  },
                },
              },
            },
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                callbacks: {
                  title: (tooltip) => lineBreak(tooltip[0].label, 60),
                },
              },
            },
            onHover: (event: any) => {
              event.native.target.style.cursor = "pointer";
            },
            onClick: (event: any, elements, chart) => {
              if (labelsLink) {
                const {
                  // ctx,
                  canvas,
                  scales: { y },
                } = chart;

                const rect = canvas.getBoundingClientRect();
                const xCoor = event.native.clientX - rect.left;
                const yCoor = event.native.clientY - rect.top;

                const left = y.left;
                const right = canvas.width;
                const height = y.height / y.ticks.length;

                const labelHeight = height / 2;

                for (let i = 0; i < y.ticks.length; i++) {
                  if (
                    xCoor >= left &&
                    xCoor <= right &&
                    yCoor >= height * i + (height / 2 - 24) &&
                    yCoor <= height * (i + 1) - labelHeight &&
                    labelsLink[i]
                  ) {
                    window.open(labelsLink[i]);
                  }
                }
              }
            },
          }}
          data={{
            labels: labels,
            datasets: [
              {
                type: "bar",
                label: "Views",
                data: data,
                borderColor: globalState?.primaryColor,
                backgroundColor: globalState?.primaryColor,
                barThickness: 8,
                borderRadius: 8,
              },
            ],
          }}
        />
      </CardContent>
    </Card>
  );
};

export default TopVideosChart;
