import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import { BrowserRouter } from "react-router-dom";

import * as Sentry from "@sentry/react";

const REACT_APP_STAGE = process.env.REACT_APP_STAGE;

Sentry.init({
  dsn: "https://96f943170d8547219a3bf3bf0e5a0312@o4505432796692480.ingest.sentry.io/4505510193463296",
  release: "sound-bites-admin@1.0.0",
  environment: REACT_APP_STAGE,
  enabled:
    REACT_APP_STAGE === "production" && !window.location.hostname.includes("localhost"),
  ignoreErrors: ["Non-Error exception captured", "Non-Error promise rejection captured"],
});

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
