import { useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";

export type OptionType = {
  label: string;
  icon: any;
  onClick?: any;
  link?: string;
};

type Props = {
  options: OptionType[];
};

const MoreOptions = ({ options }: Props) => {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  if (options.length === 0) return <></>;

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <MoreVertIcon />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { minWidth: "200px", maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {options.map((option: OptionType, index: number) =>
          option.link ? (
            <MenuItem
              key={index}
              sx={{ color: "text.secondary" }}
              component={RouterLink}
              to={option.link}
            >
              <ListItemIcon>{option.icon}</ListItemIcon>
              <ListItemText
                primary={option.label}
                primaryTypographyProps={{ variant: "body2" }}
              />
            </MenuItem>
          ) : (
            <MenuItem
              key={index}
              sx={{ color: "text.secondary" }}
              onClick={() => {
                setIsOpen(false);
                option.onClick();
              }}
            >
              <ListItemIcon>{option.icon}</ListItemIcon>
              <ListItemText
                primary={option.label}
                primaryTypographyProps={{ variant: "body2" }}
              />
            </MenuItem>
          ),
        )}
      </Menu>
    </>
  );
};

export default MoreOptions;
