import { useCallback, useEffect, useState } from "react";
import { useFormik } from "formik";
import {
  Autocomplete,
  Box,
  Card,
  CardContent,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useParams } from "react-router-dom";
import Select from "components/Core/Inputs/Select";
import { getSubscribersForDropDown, smsTest } from "api/api";
import { GetSubscriberResponse } from "typings/subscriber";
import { VIDEO_TYPES } from "enums/dropdown";
import { useDialog } from "context/dialog";

type AutocompleteType = { label: string; id: string };

const Test = () => {
  const { alertSuccess, alertError } = useDialog();
  const { id: videoId } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");

  const [subscribers, setSubscribers] = useState<AutocompleteType[]>([]);

  const getData = useCallback(async () => {
    try {
      const subs = (await getSubscribersForDropDown(
        search,
        20,
      )) as GetSubscriberResponse[];
      setSubscribers(
        subs.map((subscriber: GetSubscriberResponse) => ({
          label: `${subscriber.first_name} ${subscriber.last_name} (${subscriber.mobile})`,
          id: subscriber.id,
        })),
      );
    } catch (err) {
      console.log(err);
    }
  }, [search]);

  useEffect(() => {
    getData();
  }, [getData]);

  const formik = useFormik({
    initialValues: {
      subscriberId: "",
      videoType: "short_video",
      sendVideo: false,
      sendPoll: false,
    },
    enableReinitialize: true,
    // validationSchema: VALIDATION.general_setting,
    onSubmit: async (values) => {
      if (videoId) {
        setIsLoading(true);
        try {
          await smsTest(videoId, {
            subscriberId: values.subscriberId,
            isShort: values.videoType === "short_video",
            sendVideo: values.sendVideo,
            sendPoll: values.sendPoll,
          });

          alertSuccess("SMS sent successfully!");
        } catch (err) {
          if (err instanceof Error) {
            console.log(err);
            alertError(err.message);
          }
        } finally {
          setIsLoading(false);
        }
      }
    },
  });

  return (
    <Container style={{ padding: 0 }} maxWidth={"xl"}>
      <Card>
        <CardContent>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Box component="form" sx={{ mt: 1 }} onSubmit={formik.handleSubmit}>
                <Stack gap={2}>
                  <Select
                    id="videoType"
                    label="Video Type"
                    name="videoType"
                    options={VIDEO_TYPES}
                    value={formik.values.videoType}
                    onChange={(value) => {
                      formik.setFieldValue("videoType", value);
                    }}
                    onBlur={formik.handleBlur}
                  />

                  <Autocomplete
                    id="subscriber"
                    options={subscribers}
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        onChange={(e) => setSearch(e.target.value)}
                        label="Subscriber"
                      />
                    )}
                    onChange={(event, value) => {
                      formik.setFieldValue("subscriberId", value?.id);
                    }}
                  />

                  <FormControlLabel
                    id="sendVideo"
                    name="sendVideo"
                    label="Send Video"
                    control={<Checkbox checked={formik.values.sendVideo} />}
                    value={formik.values.sendVideo}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />

                  <FormControlLabel
                    id="sendPoll"
                    name="sendPoll"
                    label="Send Poll"
                    control={<Checkbox checked={formik.values.sendPoll} />}
                    value={formik.values.sendPoll}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />

                  <LoadingButton
                    type="submit"
                    size="large"
                    variant="contained"
                    loading={isLoading}
                  >
                    Test
                  </LoadingButton>
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Container>
  );
};

export default Test;
