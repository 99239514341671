import Signup from "components/Signup";
import useQuery from "hooks/useQuery";
import { useNavigate } from "react-router-dom";

const SignupPage = () => {
  const navigate = useNavigate();
  const {
    params: { email, token },
  } = useQuery();

  if (!email || !token) {
    navigate("/");
  }
  return <Signup />;
};

export default SignupPage;
