import React, { useEffect } from "react";
import ReactPlayer, { Config } from "react-player";
import { IconButton, Modal } from "@mui/material";
import { Box } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";

type Props = {
  link: string;
  openPlayer: boolean;
  close: () => void;
  width?: string;
  height?: string;
  config?: Config;
};

const style = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "fill-available",
  height: "90%",
};

const closeBtnStyle = {
  position: "absolute",
  color: "#fff",
  right: "20px",
  top: "20px",
};

const loaderContainer = {
  position: "absolute",
};

const VideoPlayer = ({ link, openPlayer, close, width, height, config }: Props) => {
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    if (openPlayer) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  }, [openPlayer]);

  return (
    <Modal keepMounted open={openPlayer} onClose={close}>
      <div>
        <IconButton onClick={close} sx={closeBtnStyle}>
          <CloseIcon fontSize="large" />
        </IconButton>
        <Box sx={{ ...style }}>
          {loading && (
            <Box sx={loaderContainer}>
              <CircularProgress color="success" />
            </Box>
          )}

          <ReactPlayer
            url={link}
            width={width || "600px"}
            height={height}
            muted
            controls
            config={config}
            style={{ opacity: loading ? 0 : 1 }}
          />
        </Box>
      </div>
    </Modal>
  );
};

export default VideoPlayer;
