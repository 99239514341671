import { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const useQuery = () => {
  const { search, hash } = useLocation();
  const navigate = useNavigate();

  const query = useMemo(() => new URLSearchParams(search), [search]);

  const updateQueryParams = (params: any) => {
    for (const property in params) {
      query.set(property, params[property].toString());
    }

    navigate("?" + query.toString() + hash);
  };

  const updateUri = (s: string) => {
    navigate("?" + query.toString() + s);
  };

  const deleteQueryParams = (key: string) => {
    query.delete(key);
    navigate("?" + query.toString());
  };

  return {
    query,
    params: Object.fromEntries(query),
    hash,
    updateUri,
    updateQueryParams,
    deleteQueryParams,
  };
};

export default useQuery;
