import { useEffect, useState } from "react";
import React from "react";
import {
  Box,
  Button,
  Collapse,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
} from "@mui/material";
import InputField from "components/Core/Inputs/Input";
import MultiSelect from "components/Core/Inputs/MultiSelect";
import useQuery from "hooks/useQuery";

import CancelIcon from "@mui/icons-material/Cancel";
import FilterListIcon from "@mui/icons-material/FilterList";

import { cleanObject, localStorageGetItem, localStorageSetItem } from "helpers/utils";
import { useFormik } from "formik";
import CustomMessage from "./CustomMessage";
import { useCurrentUser } from "context/user";
import { ONBOARDING_STATUS_OPTIONS, STATUS_OPTIONS } from "enums/dropdown";
import { SUBSCRIBER_ROLES_OPTION } from "enums/common";

interface Props {
  columns: any;
  tags: any;
  accounts: any;
  isIhAccount?: boolean;
  updateColumnVisibility: () => void;
  totalCount: number;
}

const Filters = ({
  columns,
  updateColumnVisibility,
  tags,
  accounts,
  isIhAccount,
  totalCount,
}: Props) => {
  const { user } = useCurrentUser();
  const { params, updateQueryParams, deleteQueryParams } = useQuery();

  const [tableColumns, setTableColumns] = useState<string[]>([]);
  const [search, setSearch] = useState(params.search || "");
  const [showAdvanceFilter, setShowAdvanceFilter] = useState(false);

  const clearSearch = () => {
    setSearch("");
    deleteQueryParams("search");
    updateQueryParams({ page: 0 });
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    // Perform any necessary logic here
    handleSearch(); // Call the handleSearch function
  };

  const handleSearch = () => {
    if (search) {
      updateQueryParams({ search, page: 0 });
    }
  };

  useEffect(() => {
    const storedColumns = JSON.parse(
      localStorageGetItem("subscriberTableColumns") || "[]",
    );
    if (storedColumns.length) {
      setTableColumns(storedColumns);
    } else {
      setTableColumns(columns.map((col: any) => col.value));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formik = useFormik({
    initialValues: {
      tag: params.tag ? params.tag.split(",") : [],
      account: params.account ? params.account.split(",") : [],
      status: params.status ? params.status.split(",") : [],
      onboarding: params.onboarding ? params.onboarding.split(",") : [],
      subscriberType: params.subscriberType ? params.subscriberType.split(",") : [],
    },
    onSubmit: async (values) => {
      const filter = cleanObject({
        tag: values.tag,
        account: values.account,
        status: values.status,
        onboarding: values.onboarding,
        subscriberType: values.subscriberType,
      });

      Object.keys(values)
        .filter((key) => !Object.keys(filter).includes(key))
        .map((key) => deleteQueryParams(key));

      updateQueryParams({ ...filter, page: 0 });
    },
  });

  const clearFilter = () => {
    formik.resetForm();
    Object.keys(formik.values).map((key) => deleteQueryParams(key));
  };

  return (
    <Stack p={2} spacing={2}>
      <Grid container spacing={2} alignItems={"center"}>
        <Grid item xs={12} sm={12} md={6} display={"flex"} alignItems="center">
          <form onSubmit={handleSubmit}>
            <Stack gap={1} flexDirection={"row"} alignItems="center">
              <InputField
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search subscriber..."
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={clearSearch} edge="end">
                        {search.length > 0 && <CancelIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <Button
                variant="contained"
                onClick={handleSearch}
                size="large"
                type="submit"
              >
                Search
              </Button>

              <IconButton onClick={() => setShowAdvanceFilter(!showAdvanceFilter)}>
                <FilterListIcon />
              </IconButton>
            </Stack>
          </form>
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <Stack
            direction={"row"}
            spacing={1}
            alignItems={"center"}
            justifyContent={"flex-end"}
          >
            {user?.isSuperAdmin && <CustomMessage totalCount={totalCount} />}

            <Box width={180}>
              <MultiSelect
                id={"tableColumns"}
                labelId={"tableColumns"}
                label={"Edit Columns"}
                options={columns}
                selectValue={tableColumns}
                setSelectValue={(values) => {
                  console.log(values);
                  localStorageSetItem("subscriberTableColumns", values);
                  setTableColumns(values);
                  updateColumnVisibility();
                }}
                placeholder="Edit Columns"
                showChips={false}
                sortSelected={false}
              />
            </Box>
          </Stack>
        </Grid>
      </Grid>

      <Collapse in={showAdvanceFilter} timeout="auto">
        <Box component={"form"} onSubmit={formik.handleSubmit}>
          <Stack gap={2}>
            <Stack
              direction={{ xs: "column", md: "row" }}
              justifyContent={"space-between"}
              gap={2}
            >
              <MultiSelect
                id={"tag"}
                name="tag"
                label={"Tags"}
                options={tags}
                selectValue={formik.values.tag}
                setSelectValue={(value) => formik.setFieldValue("tag", value)}
                placeholder={"Select Tags"}
                showChips={false}
              />

              {isIhAccount && (
                <MultiSelect
                  id={"account"}
                  name={"account"}
                  label={"Accounts"}
                  options={accounts}
                  selectValue={formik.values.account}
                  setSelectValue={(value) => formik.setFieldValue("account", value)}
                  placeholder={"Select Accounts"}
                  showChips={false}
                />
              )}

              <MultiSelect
                id={"status"}
                name={"status"}
                label={"Status"}
                options={
                  isIhAccount
                    ? [...STATUS_OPTIONS, { label: "Other", value: "PHANTOM" }]
                    : STATUS_OPTIONS
                }
                selectValue={formik.values.status}
                setSelectValue={(value) => formik.setFieldValue("status", value)}
                placeholder={"Select Status"}
                showChips={false}
              />

              <MultiSelect
                id={"subscriberType"}
                name={"subscriberType"}
                label={"Role"}
                options={SUBSCRIBER_ROLES_OPTION}
                selectValue={formik.values.subscriberType}
                setSelectValue={(value) => formik.setFieldValue("subscriberType", value)}
                placeholder={"Select Role"}
                showChips={false}
              />

              <MultiSelect
                id={"onboarding"}
                name={"onboarding"}
                label={"Onboarding"}
                options={ONBOARDING_STATUS_OPTIONS}
                selectValue={formik.values.onboarding}
                setSelectValue={(value) => formik.setFieldValue("onboarding", value)}
                placeholder={"Select Onboarding Status"}
                showChips={false}
              />
            </Stack>
            <Stack direction="row" gap={2}>
              <Button variant="contained" type="submit" size="large">
                Apply
              </Button>

              <Button variant="contained" size="large" onClick={clearFilter}>
                Clear
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Collapse>
    </Stack>
  );
};

export default Filters;
