import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Chip,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { deleteMessage, getMessages, updateMessageStatus } from "api/api";
import { StyledTableCell } from "components/Core/Table/Table.styles";
import MoreOptions from "components/Core/MoreOptions";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import Select from "components/Core/Inputs/Select";
import { useDialog } from "context/dialog";
import { formatDate, toTitleCase } from "helpers/utils";
import AddEditMessage from "./AddEditMessage";

export type Message = {
  id: string;
  type: string;
  message: string;
  status: string;
  created_at?: string;
};

export type Defaults = {
  [key: string]: {
    type: string;
    label: string;
    defaultMessage: string;
    placeholders: string[];
  };
};

type Response = {
  messages: Message[];
  defaults: Defaults;
};

const TABLE_HEAD = [
  { id: "message", label: "Message" },
  { id: "type", label: "Type" },
  { id: "status", label: "Status" },
  { id: "createdDate", label: "Created Date" },
  { id: "", label: "" },
];

const Message = ({ admin = false }) => {
  const { confirm, alertSuccess, alertError, showPageLoading } = useDialog();

  const [message, setMessage] = useState<Message | null>(null);
  const [messages, setMessages] = useState<Message[]>([]);
  const [defaults, setDefaults] = useState<Defaults>();

  const [showModal, setShowModal] = useState(false);

  const [messageTypeFilter, setMessageTypeFilter] = useState("");

  const fetchMessages = useCallback(async () => {
    try {
      showPageLoading(true);
      const res = (await getMessages(admin)) as Response;
      setDefaults(res.defaults);
      setMessages(res.messages);
      showPageLoading(false);
    } catch (err) {
      console.log(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [admin]);

  useEffect(() => {
    fetchMessages();
  }, [fetchMessages]);

  const handleDeleteMessage = async (id: string) => {
    showPageLoading(true);
    try {
      await deleteMessage(id, admin);
      alertSuccess("Message deleted successfully");

      fetchMessages();
    } catch (err: any) {
      alertError(err.message);
    }
    showPageLoading(false);
  };

  const confirmMessageDeletion = (id: string) => {
    confirm({
      title: "Delete Message!",
      content: `Are you sure you want to delete this message?`,
      cofirmText: "Delete",
      cancelText: "Cancel",
      onConfirm: () => handleDeleteMessage(id),
    });
  };

  const handleUpdateStatus = async (id: string, newStatus: string) => {
    showPageLoading(true);
    try {
      await updateMessageStatus(id, newStatus, admin);
      alertSuccess("Messages status updated successfully");

      fetchMessages();
    } catch (err: any) {
      alertError(err.message);
    }
    showPageLoading(false);
  };

  const confirmMessageStatusChange = (id: string, currentStatus: string) => {
    const newStatus = currentStatus === "ACTIVE" ? "INACTIVE" : "ACTIVE";
    const status = toTitleCase(newStatus);
    confirm({
      title: `${status} Message!`,
      content: `Are you sure you want to make message "${status}"?`,
      cofirmText: "Confirm",
      cancelText: "Cancel",
      onConfirm: () => handleUpdateStatus(id, newStatus),
    });
  };

  useEffect(() => {
    if (!showModal) {
      setMessage(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  const messageTypeOptions =
    (defaults &&
      Object.values(defaults).map((message) => ({
        label: message.label,
        value: message.type,
      }))) ||
    [];

  return (
    <Container maxWidth={"xl"}>
      <AddEditMessage
        openModal={showModal}
        defaults={defaults || {}}
        setOpenModal={setShowModal}
        refreshList={fetchMessages}
        message={message}
      />

      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Messages
        </Typography>
      </Stack>

      <Card>
        <Stack direction="row" justifyContent="space-between" p={2}>
          <Box width={"300px"}>
            <Select
              id="messageType"
              label="Message Type"
              name="messageType"
              options={[{ label: "-- Select --", value: "" }, ...messageTypeOptions]}
              value={messageTypeFilter}
              onChange={(val: string) => setMessageTypeFilter(val)}
            />
          </Box>

          <Button
            size="medium"
            variant="contained"
            onClick={() => setShowModal(true)}
            startIcon={<AddIcon />}
          >
            Add Message
          </Button>
        </Stack>

        <Table>
          <TableHead>
            <TableRow>
              {TABLE_HEAD.map((headCell) => (
                <StyledTableCell key={headCell.id}>{headCell.label}</StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!messages.length && (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  No Data Found
                </TableCell>
              </TableRow>
            )}
            {messages
              .filter((message) =>
                messageTypeFilter ? messageTypeFilter === message.type : true,
              )
              .map((row) => {
                const { id, message, type, status, created_at } = row;
                const isActive = status === "ACTIVE";

                return (
                  <TableRow hover key={id} tabIndex={-1}>
                    <TableCell width={"60%"}>{message}</TableCell>
                    <TableCell>{defaults && defaults[type].label}</TableCell>
                    <TableCell>
                      <Chip
                        label={toTitleCase(status)}
                        color={isActive ? "success" : "error"}
                      />
                    </TableCell>
                    <TableCell>{formatDate(created_at)}</TableCell>
                    <TableCell align="right">
                      <MoreOptions
                        options={[
                          {
                            label: "Edit Message",
                            icon: <EditIcon />,
                            onClick: () => {
                              setMessage(row);
                              setShowModal(true);
                            },
                          },
                          {
                            label: isActive ? "Inactive" : "Active",
                            icon: isActive ? <CloseIcon /> : <CheckIcon />,
                            onClick: () => confirmMessageStatusChange(id, status),
                          },
                          {
                            label: "Delete",
                            icon: <DeleteIcon />,
                            onClick: () => confirmMessageDeletion(id),
                          },
                        ]}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </Card>
    </Container>
  );
};

export default Message;
