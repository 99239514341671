import React, { useCallback, useEffect, useState } from "react";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import Scrollbar from "components/Core/Scrollbar";
import { getClinics } from "api/api";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreOptions from "components/Core/MoreOptions";
import { ClinicType, GetClinicsResponse } from "typings/clinic";
import { StyledTableCell } from "components/Core/Table/Table.styles";
import DeleteClinic from "components/Clinic/DeleteClinic";
import AddUpdateClinic from "components/Clinic/AddUpdateClinic";
import { useDialog } from "context/dialog";
import { formatDate } from "helpers/utils";

const Clinics = () => {
  const limit = 20;
  const { showPageLoading } = useDialog();
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const [clinics, setClinics] = useState<ClinicType[]>([]);
  const [currentClinic, setCurrentClinic] = useState<ClinicType>();

  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showAddClinicModal, setShowAddClinicModal] = useState(false);

  const TABLE_HEAD = [
    { id: "name", label: "Name" },
    { id: "description", label: "Description" },
    { id: "createdDate", label: "Created Date" },
    { id: "", label: "" },
  ];

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const getData = useCallback(() => {
    showPageLoading(true);
    getClinics(limit, page)
      .then((res) => {
        const data = res as GetClinicsResponse;
        setClinics(data.rows);
        setTotalCount(data.count);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => showPageLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, page]);

  return (
    <Card>
      {currentClinic && (
        <DeleteClinic
          openModal={showDeleteConfirm}
          setOpenModal={setShowDeleteConfirm}
          clinic={currentClinic}
          refreshList={getData}
        />
      )}

      <AddUpdateClinic
        openModal={showAddClinicModal}
        setOpenModal={setShowAddClinicModal}
        clinic={currentClinic}
        refreshList={getData}
      />

      <Scrollbar>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {TABLE_HEAD.map((headCell) => (
                  <StyledTableCell key={headCell.id}>{headCell.label}</StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {clinics.map((row: ClinicType) => {
                const { id, name, description, created_at, is_default } = row;

                return (
                  <TableRow hover key={id} tabIndex={-1}>
                    <TableCell>{name}</TableCell>
                    <TableCell>{description}</TableCell>
                    <TableCell>{formatDate(created_at)}</TableCell>
                    <TableCell align="right">
                      {!is_default && (
                        <MoreOptions
                          options={[
                            {
                              label: "Edit Clinic",
                              icon: <EditIcon width={24} height={24} />,
                              onClick: () => {
                                setCurrentClinic({
                                  id,
                                  name,
                                  description,
                                });
                                setShowAddClinicModal(true);
                              },
                            },
                            {
                              label: "Delete Clinic",
                              icon: <DeleteIcon width={24} height={24} />,
                              onClick: () => {
                                setCurrentClinic({
                                  id,
                                  name,
                                  description,
                                });
                                setShowDeleteConfirm(true);
                              },
                            },
                          ]}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10]}
          component="div"
          count={totalCount}
          rowsPerPage={limit}
          page={page}
          onPageChange={handleChangePage}
        />
      </Scrollbar>
    </Card>
  );
};

export default Clinics;
