import Confirm from "components/Core/Confirm/Confirm";
import { PropsWithChildren, createContext, useContext, useState } from "react";
import AlertToast from "components/Core/Toast";
import { AlertColor } from "@mui/material/Alert";
import PageLoading from "components/Core/PageLoading";

type ConfirmType = {
  title: string;
  content?: any;
  cofirmText?: string;
  cancelText?: string;
  onConfirm: () => void;
  onCancel?: () => void;
};

type AlertType = {
  type: AlertColor;
  message: string;
};

type DialogProps = {
  confirm: (state: ConfirmType) => void;
  alertSuccess: (message: string) => void;
  alertError: (message: string) => void;
  showPageLoading: (loading: boolean) => void;
};

const DialogContext = createContext<DialogProps>({
  confirm: (state: ConfirmType) => state,
  alertSuccess: (message: string) => message,
  alertError: (message: string) => message,
  showPageLoading: (loading: boolean) => loading,
});

export const DialogProvider = ({ children }: PropsWithChildren<{}>) => {
  // --------------- page loading -----------------------
  const [pageLoading, setPageLoading] = useState(false);

  const showPageLoading = (loading: boolean) => {
    setPageLoading(loading);
  };

  // --------------- confirm -----------------------
  const [triggerConfirm, setTriggerConfirm] = useState(false);
  const [confirmState, setConfirmState] = useState<ConfirmType>({
    title: "",
    content: "",
    cofirmText: "",
    cancelText: "",
    onConfirm: () => {},
    onCancel: () => {},
  });

  const confirm = (props: ConfirmType) => {
    setTriggerConfirm(true);
    setConfirmState(props);
  };

  const resetDialog = () => {
    setTriggerConfirm(false);
  };

  const onConfirm = () => {
    resetDialog();
    confirmState.onConfirm();
  };

  const onDismiss = () => {
    resetDialog();
    if (confirmState.onCancel) {
      confirmState.onCancel();
    }
  };

  // --------------- alert -----------------------

  const [triggerAlert, setTriggerAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState<AlertType>({
    type: "success",
    message: "",
  });

  const alertSuccess = (message: string) => {
    setTriggerAlert(true);
    setAlertMessage({
      type: "success",
      message: message,
    });
  };

  const alertError = (message: string) => {
    setTriggerAlert(true);
    setAlertMessage({
      type: "error",
      message: message,
    });
  };

  const onDismissAlert = () => {
    setTriggerAlert(false);
  };

  return (
    <DialogContext.Provider
      value={{ confirm, alertSuccess, alertError, showPageLoading }}
    >
      <PageLoading loading={pageLoading} />

      <Confirm
        isOpen={triggerConfirm}
        title={confirmState.title}
        description={confirmState.content}
        confirmBtnText={confirmState.cofirmText || "Ok"}
        cancelBtnText={confirmState.cancelText || "Cancel"}
        confirmfn={onConfirm}
        cancelfn={onDismiss}
      />

      <AlertToast
        open={triggerAlert}
        alertType={alertMessage.type}
        message={alertMessage.message}
        onClose={onDismissAlert}
      />

      {children}
    </DialogContext.Provider>
  );
};

export const useDialog = () => {
  return useContext(DialogContext);
};
