import { Stack, Typography } from "@mui/material";
import useQuery from "hooks/useQuery";

const Header = () => {
  const { params } = useQuery();
  const { name: inviterName } = params;

  return (
    <Stack>
      <Typography variant="h4">Subscribers</Typography>
      {inviterName && <Typography variant="h6">Invited by {inviterName}</Typography>}
    </Stack>
  );
};

export default Header;
