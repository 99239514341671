import { useEffect, useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { alpha } from "@mui/material/styles";
import {
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  MenuItem,
  Typography,
} from "@mui/material";

import MenuPopover from "components/Menu/PopoverMenu";

import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import DashboardIcon from "@mui/icons-material/Dashboard";
import MessageIcon from "@mui/icons-material/Message";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import { getUserAttributes, signOut } from "helpers/auth";
import pages from "enums/pages";
import { useCurrentUser } from "context/user";

const AccountPopover = () => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState<any>();
  const { user: _user } = useCurrentUser();

  const MENU_OPTIONS = [];

  if (_user?.isSuperAdmin) {
    MENU_OPTIONS.push({
      label: "Dashboard",
      linkTo: pages.ADMIN_DASHBOARD_PAGE,
      icon: <DashboardIcon />,
    });
    MENU_OPTIONS.push({
      label: "Accounts",
      linkTo: pages.ADMIN_ACCOUNTS_PAGE,
      icon: <AccountBalanceIcon />,
    });
    MENU_OPTIONS.push({
      label: "New Clinics",
      linkTo: pages.OTHER_CLINICS,
      icon: <LocalHospitalIcon />,
    });
    MENU_OPTIONS.push({
      label: "Messages",
      linkTo: pages.ADMIN_MESSAGES_PAGE,
      icon: <MessageIcon />,
    });
  }

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const logout = () => {
    if (signOut()) {
      window.location.href = `/`;
    }
  };

  useEffect(() => {
    getUserAttributes().then((data) => setUser(data));
  }, []);

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
            },
          }),
        }}
      >
        <Avatar alt="photoURL" />
      </IconButton>

      <MenuPopover
        open={open || false}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {user?.given_name}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {user?.email}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        <MenuItem
          key={""}
          to={pages.PROFILE_PAGE}
          component={RouterLink}
          onClick={handleClose}
          sx={{ typography: "body2", py: 1, px: 2.5 }}
        >
          <Box sx={{ mx: 2 }}>
            <ManageAccountsIcon />
          </Box>
          Profile
        </MenuItem>

        {_user?.isSuperAdmin && <Divider sx={{ my: 1 }} />}

        {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={handleClose}
            sx={{ typography: "body2", py: 1, px: 2.5 }}
          >
            <Box sx={{ mx: 2 }}>{option.icon}</Box>
            {option.label}
          </MenuItem>
        ))}

        <Divider sx={{ my: 1 }} />

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button fullWidth color="inherit" variant="outlined" onClick={logout}>
            Logout
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
};

export default AccountPopover;
