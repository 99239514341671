import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import { MobileDateTimePickerProps } from "@mui/x-date-pickers/MobileDateTimePicker/MobileDateTimePicker.types";
import { Box, FormHelperText, InputLabel, Stack, Tooltip } from "@mui/material";
import moment from "moment";
import styled from "styled-components";
import InfoIcon from "@mui/icons-material/Info";

const MobilePicker = styled(MobileDateTimePicker)`
  width: 100%;
`;

interface Props {
  error?: boolean;
  helperText?: React.ReactNode;
  tooltip?: string;
  disablePast?: boolean;
  shouldDisableDate?: (day: any) => boolean;
}

const DateTimePicker = (Props: Props & MobileDateTimePickerProps<any>) => {
  return (
    <Box mb={2}>
      {Props.label && (
        <Stack flexDirection="row" gap={1} style={{ marginBottom: 5 }}>
          <InputLabel error={Props?.error}>{Props.label}</InputLabel>
          {Props?.tooltip && (
            <Tooltip title={Props.tooltip}>
              <InfoIcon color="info" />
            </Tooltip>
          )}
        </Stack>
      )}
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <MobilePicker
          {...Props}
          label=""
          defaultValue={Props.defaultValue ? moment(Props.defaultValue) : null}
          minutesStep={5}
          disablePast={Props.disablePast}
          shouldDisableDate={Props.shouldDisableDate}
        />
      </LocalizationProvider>
      {Props.helperText && (
        <FormHelperText error={Props.error}>{Props.helperText}</FormHelperText>
      )}
    </Box>
  );
};

export default DateTimePicker;
