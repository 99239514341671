import { useState } from "react";
import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import { createAccount } from "api/api";
import { LoadingButton } from "@mui/lab";
import { toSlug } from "helpers/utils";
import { useNavigate } from "react-router-dom";
import pages from "enums/pages";
import { useFormik } from "formik";
import InputField from "components/Core/Inputs/Input";
import VALIDATION from "helpers/validation_schemas";
import { useDialog } from "context/dialog";

const CreateAccount = () => {
  const { alertSuccess, alertError } = useDialog();
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
      companyName: "",
      slug: "",
    },
    validationSchema: VALIDATION.add_account,
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      try {
        await createAccount({
          email: values.email,
          name: values.companyName,
          slug: values.slug,
        });
        alertSuccess("Account created, invite sent successfully");

        setTimeout(() => {
          navigate(pages.ADMIN_ACCOUNTS_PAGE, { replace: true });
        }, 2000);
      } catch (err) {
        if (err instanceof Error) {
          console.log(err);
          alertError(err.message);
        }
      }

      resetForm();
      setIsLoading(false);
    },
  });

  return (
    <Container>
      <Grid container>
        <Grid item md={5}>
          <Typography
            component="h1"
            variant="h5"
            fontWeight={600}
            textTransform={"capitalize"}
          >
            Create Account
          </Typography>

          <Box component="form" onSubmit={formik.handleSubmit} sx={{ mt: 1 }}>
            <Stack gap={2}>
              <InputField
                label="Company Name"
                id="companyName"
                name="companyName"
                value={formik.values.companyName}
                onChange={(e) => {
                  formik.handleChange(e);
                  formik.setFieldValue("slug", toSlug(e.target.value));
                }}
                onBlur={formik.handleBlur}
                error={formik.touched.companyName && Boolean(formik.errors.companyName)}
                helperText={formik.touched.companyName && formik.errors.companyName}
                fullWidth
                labelInsideInputField
              />

              <InputField
                label="Slug"
                id="slug"
                name="slug"
                value={formik.values.slug}
                onChange={formik.handleChange}
                onBlur={(e) => {
                  formik.handleBlur(e);
                  formik.setFieldValue("slug", toSlug(e.target.value));
                }}
                error={formik.touched.slug && Boolean(formik.errors.slug)}
                helperText={formik.touched.slug && formik.errors.slug}
                fullWidth
                labelInsideInputField
              />

              <InputField
                label="Email"
                id="email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                fullWidth
                labelInsideInputField
              />

              <LoadingButton
                fullWidth
                size="large"
                variant="contained"
                loading={isLoading}
                type="submit"
              >
                Create Account
              </LoadingButton>
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default CreateAccount;
