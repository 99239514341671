import { useEffect, useState } from "react";

import { Box, Button, Stack, Typography } from "@mui/material";
import Modal from "components/Core/Modal";
import InputField from "components/Core/Inputs/Input";

import { LoadingButton } from "@mui/lab";
import { useDialog } from "context/dialog";
import useQuery from "hooks/useQuery";
import { sendCustomSMS } from "api/api";

interface Props {
  totalCount: number;
}

const CustomMessage = ({ totalCount }: Props) => {
  const { confirm, alertSuccess, alertError } = useDialog();
  const { params } = useQuery();

  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");

  const handleSendCustomMessage = async () => {
    try {
      setIsLoading(true);
      await sendCustomSMS({
        filter: params,
        message,
      });
      setOpenModal(false);

      alertSuccess("Message sent all susbcribers");
    } catch (error) {
      console.log(error);
      alertError("Something went wrong, please try again!");
    } finally {
      setIsLoading(false);
    }
  };

  const confirmCustomMessage = () => {
    confirm({
      title: "Send Message!",
      content: `Please confirm you are about to send this message to ${totalCount} subscribers`,
      cofirmText: "Send",
      cancelText: "Cancel",
      onConfirm: () => handleSendCustomMessage(),
    });
  };

  useEffect(() => {
    if (!openModal) {
      setMessage("");
    }
  }, [openModal]);

  return (
    <>
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Typography variant="h4" gutterBottom align="center">
          Message
        </Typography>

        <Box component="form">
          <Stack gap={2}>
            <InputField
              id="message"
              name="message"
              label="Message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              fullWidth
              labelInsideInputField
              multiline
              rows={8}
            />

            <LoadingButton
              fullWidth
              size="large"
              variant="contained"
              loading={isLoading}
              onClick={confirmCustomMessage}
              disabled={!message.length}
            >
              Send
            </LoadingButton>
          </Stack>
        </Box>
      </Modal>

      <Button
        variant="contained"
        size="large"
        sx={{ width: 210 }}
        onClick={() => setOpenModal(true)}
      >
        Send Custom Message
      </Button>
    </>
  );
};

export default CustomMessage;
