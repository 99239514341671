import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { User } from "typings/user";
import { useCurrentState } from "./global";
import { getUserDataFromToken } from "helpers/auth";

export type UserProps = {
  user?: User;
};

type UserContextProps = {
  user?: User;
  refreshUser: () => Promise<User>;
};

const UserContext = createContext<UserContextProps>({
  refreshUser: () => Promise.reject("No current user."),
});

export const UserProvider = ({
  children,
  user: _user,
}: PropsWithChildren<{ user?: User }>) => {
  const [user, setUser] = useState<User | undefined>(_user);
  const { globalState, setGlobalState } = useCurrentState();

  const refreshUser = useCallback(() => {
    const data = getUserDataFromToken();
    setUser(data);
    return data;
  }, []);

  useEffect(() => {
    if (!user) {
      const data = refreshUser();
      const state: any = {
        ...globalState,
      };
      if (data?.account?.primaryColor) state.primaryColor = data.account.primaryColor;
      if (data?.account?.secondaryColor)
        state.secondaryColor = data.account.secondaryColor;
      if (data?.account?.logo) state.logo = data.account.logo;
      if (data?.role) state.role = data.role;
      state.isSuperAdmin = !!data?.isSuperAdmin;
      state.accountName = data.account.name;
      setGlobalState({ ...state });
    }
  }, [refreshUser, setGlobalState, user, globalState]);

  return (
    <UserContext.Provider value={{ user, refreshUser }}>{children}</UserContext.Provider>
  );
};

export const useCurrentUser = () => {
  return useContext(UserContext);
};
