import { ReactNode } from "react";
import { Stack, Typography } from "@mui/material";
import moment from "moment";

type Props = {
  children: ReactNode;
};

const DashboardHeader = ({ children }: Props) => {
  const currentHour = moment().utc().hours();
  const h = currentHour % 3;

  const lastUpdate = moment(
    moment()
      .utcOffset(0)
      .set({
        hour: h === 0 ? currentHour : currentHour - h,
        minute: 0,
        second: 0,
        millisecond: 0,
      })
      .toISOString(),
  ).format("MMM DD, YYYY HH:mm:ss");

  return (
    <Stack direction="column" mb={3}>
      {children}
      <Typography variant="subtitle2">Last Refresh: {lastUpdate}</Typography>
    </Stack>
  );
};

export default DashboardHeader;
