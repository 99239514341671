import React, { useEffect, useState } from "react";
import { Box, Card, CardContent, Checkbox, FormControlLabel, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { getAccount, updateAccount } from "api/api";
import ColorPicker from "components/Core/ColorPicker";
import palette from "theme/palette";
import { AccountType } from "typings/account";
import { useCurrentState } from "context/global";
import FileUpload from "components/Core/Inputs/FileUpload";
import { UPLOAD_DIR } from "enums/common";
import LogoImg from "assets/ideology_logo.png";
import { refreshToken } from "helpers/auth";
import VALIDATION from "helpers/validation_schemas";
import InputField from "components/Core/Inputs/Input";
import { useFormik } from "formik";
import { useDialog } from "context/dialog";
import { uploadImage } from "helpers/image";

const General = () => {
  const { alertSuccess, alertError, showPageLoading } = useDialog();
  const [account, setAccount] = useState<AccountType>();

  const [isLoading, setIsLoading] = useState(false);

  const { globalState, setGlobalState } = useCurrentState();

  useEffect(() => {
    showPageLoading(true);
    getAccount()
      .then((res) => {
        const data = res as AccountType;
        setAccount(data);
      })
      .catch()
      .finally(() => showPageLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formik = useFormik({
    initialValues: {
      name: account?.name || "",
      slug: account?.slug || "",
      primaryColor: account?.primary_color || palette.primary.main,
      secondaryColor: account?.secondary_color || palette.secondary.main,

      logo: account?.logo || LogoImg,
      logoFile: undefined,

      pollBySms: account?.settings?.pollBySms || false,
      hideOnRegister: account?.settings?.hideOnRegister || false,
    },
    enableReinitialize: true,
    validationSchema: VALIDATION.general_setting,
    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        let logoUrl;
        if (values.logoFile) {
          logoUrl = await uploadImage(values.logoFile, account?.id, UPLOAD_DIR.LOGO);
        }

        await updateAccount({
          primaryColor: values.primaryColor,
          secondaryColor: values.secondaryColor,
          logo: logoUrl || formik.values.logo,
          settings: {
            ...(account?.settings || {}),
            pollBySms: values.pollBySms,
            hideOnRegister: values.hideOnRegister,
          },
        });
        await refreshToken();
        alertSuccess("Setting saved successfully");

        // update global state
        const states = {
          ...globalState,
          primaryColor: values.primaryColor,
          secondaryColor: values.secondaryColor,
          accountName: values.name,
        };
        if (logoUrl) states.logo = logoUrl;
        setGlobalState({ ...states });
      } catch (err) {
        if (err instanceof Error) {
          console.log(err);
          alertError(err.message);
        }
      }
      setIsLoading(false);
    },
  });

  return (
    <>
      <Card>
        <CardContent>
          <Box component="form" sx={{ mt: 1 }} onSubmit={formik.handleSubmit}>
            <Stack gap={2}>
              <InputField
                id="name"
                label="Name"
                disabled
                value={formik.values.name}
                fullWidth
                labelInsideInputField
              />
              <InputField
                id="slug"
                label="Slug"
                disabled
                value={formik.values.slug}
                fullWidth
                labelInsideInputField
              />

              <ColorPicker
                id="primaryColor"
                name="primaryColor"
                label="Primary Color"
                value={formik.values.primaryColor}
                onChange={(color) => formik.setFieldValue("primaryColor", color.hex)}
              />

              <ColorPicker
                id="secondaryColor"
                name="secondaryColor"
                label="Secondary Color"
                value={formik.values.secondaryColor}
                onChange={(color) => formik.setFieldValue("secondaryColor", color.hex)}
              />

              <FileUpload
                id="logo"
                label="Logo"
                name="logo"
                accept="image"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  if (event.target.files !== null && event.target?.files?.length > 0) {
                    formik.setFieldValue("logoFile", event.target.files[0]);
                  }
                }}
                onDrop={(event: React.DragEvent<HTMLElement>) => {
                  formik.setFieldValue("logoFile", event.dataTransfer.files[0]);
                }}
                imageButton
                image={{
                  url: formik.values.logo,
                }}
                error={formik.touched.logo && Boolean(formik.errors.logo)}
                helperText={formik.touched.logo && formik.errors.logo}
              />

              <FormControlLabel
                id="pollBySms"
                name="pollBySms"
                label="Send Poll By SMS"
                control={<Checkbox checked={formik.values.pollBySms} />}
                value={formik.values.pollBySms}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />

              <FormControlLabel
                id="hideOnRegister"
                name="hideOnRegister"
                label="Hide account on register page"
                control={<Checkbox checked={formik.values.hideOnRegister} />}
                value={formik.values.hideOnRegister}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />

              <Box textAlign={"right"}>
                <LoadingButton
                  type="submit"
                  size="large"
                  variant="contained"
                  loading={isLoading}
                >
                  Save Settings
                </LoadingButton>
              </Box>
            </Stack>
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default General;
