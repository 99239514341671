import { useEffect, useState } from "react";
import { useFormik } from "formik";

import { Box, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import Modal from "components/Core/Modal";
import Select from "components/Core/Inputs/Select";
import VALIDATION from "helpers/validation_schemas";
import { assignClinicToAccount, getAccountsList } from "api/api";
import { ClinicType } from "typings/clinic";
import { useDialog } from "context/dialog";

type OptionType = { label: string; value: string };

type Props = {
  openModal: boolean;
  setOpenModal: (x: boolean) => void;
  clinic: ClinicType;
  refreshList: () => void;
};

const AssignClinicToAccount = ({
  clinic,
  openModal,
  setOpenModal,
  refreshList,
}: Props) => {
  const { alertSuccess, alertError } = useDialog();

  const [isLoading, setIsLoading] = useState(false);
  const [accounts, setAccounts] = useState<OptionType[]>([]);

  useEffect(() => {
    getAccountsList().then((data: any) => {
      setAccounts([...data.accounts.map((a: any) => ({ label: a.name, value: a.id }))]);
    });
  }, []);

  const formik = useFormik({
    initialValues: {
      accountId: "",
    },
    enableReinitialize: true,
    validationSchema: VALIDATION.assign_clinic_to_account_schema,
    onSubmit: async (values, { resetForm }) => {
      if (!clinic.id) return;

      setIsLoading(true);
      try {
        await assignClinicToAccount(clinic.id, values.accountId);
        alertSuccess("Assign clinic to new account successfully");

        refreshList();
        setOpenModal(false);
      } catch (err: any) {
        alertError(err.message);
      }
      setIsLoading(false);
      resetForm();
    },
  });

  useEffect(() => {
    if (!openModal) {
      formik.resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal]);

  return (
    <>
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Typography variant="h5" gutterBottom>
          Assign account to clinic &ldquo;{clinic.name}&rdquo;
        </Typography>

        <Box component="form" onSubmit={formik.handleSubmit}>
          <Select
            id="accountId"
            label="Accounts"
            name="accountId"
            options={accounts}
            value={formik.values.accountId}
            onChange={(value) => formik.setFieldValue("accountId", value)}
            error={formik.touched.accountId && Boolean(formik.errors.accountId)}
            helperText={formik.touched.accountId && formik.errors.accountId}
          />

          <LoadingButton
            fullWidth
            type="submit"
            size="large"
            variant="contained"
            loading={isLoading}
            sx={{ my: 2 }}
          >
            Assign Account
          </LoadingButton>
        </Box>
      </Modal>
    </>
  );
};

export default AssignClinicToAccount;
