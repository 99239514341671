import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import { Chip, Container, Link } from "@mui/material";

import Scrollbar from "components/Core/Scrollbar/Scrollbar";
import { getVideoLogs } from "api/api";
import { useDialog } from "context/dialog";
import ChipEllipsis from "components/Core/ChipEllipsis";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import Table from "components/Core/Table/Table";
import useQuery from "hooks/useQuery";
import pages from "enums/pages";
import { formatDate } from "helpers/utils";

export const SMS_STATUS: any = {
  accepted: "default",
  queued: "warning",
  sent: "info",
  delivered: "success",
  failed: "error",
  undelivered: "error",
};

const columnHelper = createColumnHelper<any>();

interface LogType {
  subscriber_id: string;
  first_name: string;
  last_name: string;
  email: string;
  mobile: string;
  account_name: string;
  medical_designation: string;
  tags: string[];
  sent_at: string;
  watched_at: string;
  status: string;
  twilio_sms_id: string;
}

interface LogResponse {
  logs: {
    rows: LogType[];
    count: number;
  };
}

const VideoLogs = () => {
  const { showPageLoading } = useDialog();
  const { id } = useParams();

  const limit = 20;
  const { params } = useQuery();
  const page = +params.page || 0;

  const [logs, setLogs] = useState<LogType[]>([]);
  // const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const fetchData = useCallback(
    async (limit: number, page: number) => {
      showPageLoading(true);
      try {
        if (id) {
          const res: any = (await getVideoLogs(id, page, limit)) as LogResponse;
          const { rows, count } = res.logs;
          setLogs(rows);
          setTotalCount(count);
        }
      } catch (err) {
        console.log(err);
      } finally {
        showPageLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id],
  );

  useEffect(() => {
    fetchData(limit, page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, page]);

  const handleCellClick = ({ row, column }: any) => {
    const { id, first_name, last_name, email, medical_designation, account_name } =
      row.original;
    const dashboard =
      pages.SUBSCRIBER_DASHBOARD_PAGE.replace(":subscriberId", id) +
      `?name=${
        first_name + " " + last_name
      }&email=${email}&account=${account_name}&md=${medical_designation}`;

    return (
      <Link href={dashboard} underline="none" color="inherit">
        {row.original[column.id]}
      </Link>
    );
  };

  // table
  const columns: any = [
    columnHelper.accessor("first_name", {
      id: "first_name",
      header: "First Name",
      cell: handleCellClick,
    }),
    columnHelper.accessor("last_name", {
      id: "last_name",
      header: "Last Name",
      cell: handleCellClick,
    }),
    columnHelper.accessor("email", {
      id: "email",
      header: "Email",

      cell: handleCellClick,
    }),
    columnHelper.accessor("mobile", {
      id: "mobile",
      header: "Mobile",
    }),
    columnHelper.accessor("medical_designation", {
      id: "medical_designation",
      header: "Medical Designation",
    }),
    columnHelper.accessor("tags", {
      id: "tags",
      header: "Tags",
      cell: (info) => <ChipEllipsis items={info.getValue()} />,
    }),
    columnHelper.accessor("account_name", {
      id: "account_name",
      header: "Account",
    }),

    columnHelper.accessor("sent_at", {
      id: "sent_at",
      header: "Sent Date",
      cell: (info) => formatDate(info.getValue(), true),
    }),
    columnHelper.accessor("watched_at", {
      id: "watched_at",
      header: "Watched Date",
      cell: (info) => formatDate(info.getValue(), true),
    }),
    columnHelper.accessor("twilio_sms_id", {
      id: "twilio_sms_id",
      header: "Message Id",
    }),
    columnHelper.accessor("status", {
      id: "status",
      header: "Twilio Status",
      cell: (info) => {
        const status = info.getValue();
        return status ? (
          <Chip
            label={status}
            color={SMS_STATUS[status]}
            style={{ textTransform: "capitalize" }}
          />
        ) : (
          <></>
        );
      },
    }),
  ];

  const table = useReactTable({
    data: logs,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Container maxWidth={"xl"}>
      <Scrollbar>
        <Table table={table} totalCount={totalCount} rowsPerPage={limit} />
      </Scrollbar>
    </Container>
  );
};

export default VideoLogs;
