import { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { getSubscriberVideoMetrics } from "api/api";
import Select from "components/Core/Inputs/Select";
import moment from "moment";
import GridCard from "./Card";
import TopVideoChart from "./TopVideosChart";
import VideoViewChart from "./VideoViewChart";
import { secondsToTime } from "helpers/utils";
import { useParams } from "react-router-dom";
import useQuery from "hooks/useQuery";
import pages from "enums/pages";
import Header from "./DashboardHeader";
import { QUICK_FILTER_OPTIONS } from "enums/dropdown";
import { BEGINNING_DATE } from "enums/common";
import { useDialog } from "context/dialog";

type ChartProps = { labels: string[]; labelLinks?: string[]; data: number[] };

const SubscriberDashboard = () => {
  const { showPageLoading } = useDialog();
  const [quickFilterVal, setQuickFilter] = useState<number>(
    QUICK_FILTER_OPTIONS[0].value,
  );

  const [totalVideosSent, setTotalVideosSent] = useState(0);
  const [totalViews, setTotalViews] = useState(0);
  const [totalPlayTime, setTotalPlayTime] = useState(0);
  const [avgPlaytime, setAvgPlaytime] = useState(0);
  const [neverOpenedVideosCount, setNeverOpenedVideosCount] = useState(0);
  const [openedVideosCount, setOpenedVideosCount] = useState(0);
  const [totalLinkShared, setTotalLinkShared] = useState(0);
  const [totalSubscribedUsingLink, setTotalSubscribedUsingLink] = useState(0);
  const [totalLinkClicked, setTotalLinkClicked] = useState(0);
  const [topVideos, setTopVideos] = useState<ChartProps>();
  const [videosViewsOverTime, setVideosViewsOverTime] = useState<ChartProps>();

  const { subscriberId } = useParams();
  const { params: queryParams } = useQuery();
  const { name, email, account, md } = queryParams;

  const getSubscriberStatsData = useCallback(async () => {
    showPageLoading(true);
    try {
      const fromDate = quickFilterVal
        ? moment().subtract(quickFilterVal, "days").format("YYYY-MM-DD")
        : moment(BEGINNING_DATE).format("YYYY-MM-DD");
      const toDate = moment().format("YYYY-MM-DD");

      if (fromDate && toDate && subscriberId) {
        const data: any = await getSubscriberVideoMetrics(subscriberId, fromDate, toDate);

        setTotalVideosSent(data.totalVideosSent);
        setTotalViews(data.videoViewCount);
        setTotalPlayTime(data.totalPlaytime);
        setAvgPlaytime(data.avgPlaytime);
        setNeverOpenedVideosCount(data.neverOpenedVideos);
        setOpenedVideosCount(data.totalOpenedVideos);
        setTotalLinkShared(data.totalLinkShared);
        setTotalSubscribedUsingLink(data.totalSubscribedUsingLink);
        setTotalLinkClicked(data.totalLinkClicked);
        setTopVideos({
          labels: data.topVideos.map((v: any) => v.title),
          labelLinks: data.topVideos.map(
            (v: any) =>
              pages.VIDEO_DASHBOARD_PAGE.replace(":videoId", v.id) + `?name=${v.title}`,
          ),
          data: data.topVideos.map((v: any) => v.count),
        });

        let days: string[] = [];
        let viewsCounts: number[] = [];

        if (quickFilterVal) {
          days = [...Array(quickFilterVal)].map((_, i) =>
            moment().subtract(i, "days").format("YYYY-MM-DD"),
          );
          viewsCounts = [...Array(quickFilterVal)].map(() => 0);
          data.videosViewedOverTime.map(
            (r: any) => (viewsCounts[days.indexOf(r.day)] = r.count),
          );
        } else {
          days = data.videosViewedOverTime.map((r: any) => r.day);
          viewsCounts = data.videosViewedOverTime.map((r: any) => r.count);
        }

        setVideosViewsOverTime({
          labels: days,
          data: viewsCounts,
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      showPageLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quickFilterVal]);

  useEffect(() => {
    getSubscriberStatsData();
  }, [getSubscriberStatsData]);

  const gridData = [
    {
      title: "Videos Sent",
      helperText: "videos Sent",
      stats: totalVideosSent,
    },
    {
      title: "Open",
      helperText: "videos opened",
      stats: openedVideosCount,
    },
    {
      title: "Full View",
      helperText: "full views",
      stats: totalViews,
    },
    {
      title: "Never watched",
      helperText: "videos not opened",
      stats: neverOpenedVideosCount,
    },
    {
      title: "Playtime",
      helperText: "",
      stats: secondsToTime(totalPlayTime) || "0s",
    },
    {
      title: "Avg playtime",
      helperText: "",
      stats: secondsToTime(avgPlaytime) || "0s",
    },
  ];

  const sharedLinkStats = [
    {
      title: "Total Video Shared",
      helperText: "video shared",
      stats: totalLinkShared || "0",
    },
    {
      title: "Total Invited Subscribers",
      helperText: "subscribers",
      stats: totalSubscribedUsingLink || "0",
    },
    {
      title: "Total Link Clicked",
      helperText: "clicks",
      stats: totalLinkClicked || "0",
    },
    {
      title: "Conversion rate",
      helperText: "",
      stats: Math.floor(totalSubscribedUsingLink / totalLinkShared) || "0",
    },
  ];

  return (
    <Container maxWidth={"xl"}>
      <Header>
        <Typography variant="h4">
          {name} ({email}) &lt;{md}&gt;
        </Typography>
        <Typography variant="h5">Account: {account}</Typography>
      </Header>

      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
        <Box>
          <Typography variant="h5">
            {QUICK_FILTER_OPTIONS.find((o) => o.value === quickFilterVal)?.label}
          </Typography>
        </Box>
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          justifyContent="space-between"
        >
          <Button
            size={"large"}
            variant="contained"
            component={RouterLink}
            to={`${pages.SUBSCRIBERS_PAGE}?invitedby=${subscriberId}&name=${name}`}
          >
            Invited Subscribers
          </Button>
          <Box width={200}>
            <Select
              id="quickFilter"
              label="Quick Filter"
              name="quickFilter"
              options={QUICK_FILTER_OPTIONS}
              value={quickFilterVal}
              onChange={(val: number) => setQuickFilter(val)}
            />
          </Box>
        </Stack>
      </Stack>

      <Grid container spacing={3} mb={3}>
        {gridData.map((d: any, index: number) => (
          <Grid key={index} item xs={12} sm={6} md={4} lg={4} xl>
            <GridCard title={d.title} helperText={d.helperText} stats={d.stats} />
          </Grid>
        ))}
      </Grid>

      <Grid container spacing={3} mb={3}>
        {sharedLinkStats.map((d: any, index: number) => (
          <Grid key={index} item xs={12} sm={6} md={4} lg={4} xl>
            <GridCard title={d.title} helperText={d.helperText} stats={d.stats} />
          </Grid>
        ))}
      </Grid>

      <Grid container spacing={2} mb={3}>
        <Grid item xs={12}>
          <TopVideoChart
            height={"70%"}
            labels={topVideos?.labels || []}
            data={topVideos?.data || []}
            labelsLink={topVideos?.labelLinks}
          />
        </Grid>
      </Grid>

      <Card>
        <CardContent>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent={"space-between"}
            mb={2}
            spacing={1}
          >
            <Typography fontSize={18} mb={3}>
              Videos Viewed Over Time
            </Typography>
          </Stack>
          <VideoViewChart
            height={"70%"}
            labels={videosViewsOverTime?.labels || []}
            data={videosViewsOverTime?.data || []}
          />
        </CardContent>
      </Card>
    </Container>
  );
};

export default SubscriberDashboard;
