import { useEffect, useMemo, useState } from "react";
import { CssBaseline } from "@mui/material";
import { StyledEngineProvider, ThemeProvider, createTheme } from "@mui/material/styles";
import palette from "./palette";
import typography from "./typography";
import componentsOverride from "./overrides";
import shadows, { customShadows } from "./shadows";
import { useCurrentState } from "context/global";

import { alpha } from "@mui/material/styles";

const ThemeConfig = ({ children }: any) => {
  const { globalState } = useCurrentState();

  const [primaryColorPalettes, setPrimaryColorPalettes] = useState(palette.primary);
  const [secondaryColorPalettes, setSecondaryColorPalettes] = useState(palette.secondary);
  const [successColorPalettes, setSuccessColorPalettes] = useState(palette.success);

  useEffect(() => {
    if (globalState?.primaryColor) {
      setPrimaryColorPalettes({
        lighter: alpha(globalState.primaryColor, 0.08),
        light: alpha(globalState.primaryColor, 0.24),
        main: globalState.primaryColor,
        dark: alpha(globalState.primaryColor, 0.48),
        darker: alpha(globalState.primaryColor, 0.8),
        contrastText: "#fff",
      });
    }
    if (globalState?.secondaryColor) {
      setSecondaryColorPalettes({
        lighter: alpha(globalState.secondaryColor, 0.08),
        light: alpha(globalState.secondaryColor, 0.24),
        main: globalState.secondaryColor,
        dark: alpha(globalState.secondaryColor, 0.48),
        darker: alpha(globalState.secondaryColor, 0.8),
        contrastText: "#fff",
      });
    }

    if (globalState?.successColor) {
      setSuccessColorPalettes({
        lighter: alpha(globalState.successColor, 0.08),
        light: alpha(globalState.successColor, 0.24),
        main: globalState.successColor,
        dark: alpha(globalState.successColor, 0.48),
        darker: alpha(globalState.successColor, 0.8),
        contrastText: "#fff",
      });
    }
  }, [globalState]);

  const themeOptions: any = useMemo(
    () => ({
      palette: {
        ...palette,
        primary: primaryColorPalettes,
        secondary: secondaryColorPalettes,
        success: successColorPalettes,
      },
      shape: { borderRadius: 8 },
      typography,
      shadows,
      customShadows,
    }),
    [primaryColorPalettes, secondaryColorPalettes, successColorPalettes],
  );

  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default ThemeConfig;
