import Button, { ButtonProps } from "@mui/material/Button";
import { Progress } from "./Button.styles";

type Props = {
  isLoading?: boolean;
} & ButtonProps;

const Btn = ({
  children,
  onClick,
  fullWidth,
  variant,
  disabled,
  isLoading,
  ...rest
}: Props) => {
  return (
    <Button
      fullWidth={fullWidth}
      variant={variant}
      onClick={onClick}
      disabled={isLoading ? true : disabled}
      size="large"
      {...rest}
    >
      {children}
      {isLoading && <Progress />}
    </Button>
  );
};
export default Btn;
