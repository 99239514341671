import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import moment from "moment";

import { IconButton, InputAdornment, Popover, TextField } from "@mui/material";
import DateRangeIcon from "@mui/icons-material/DateRange";

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateRange } from "react-date-range";

export type RangeType = {
  fromDate: Date | undefined;
  toDate: Date | undefined;
};

type Props = {
  dates?: RangeType;
  label?: string;
  onCalendarClose?: () => void;
  setDateRange: Dispatch<SetStateAction<RangeType>>;
};

const DateRangePicker = ({ label, setDateRange, onCalendarClose }: Props) => {
  const dateFormat = "MM-DD-YYYY";

  const [state, setState] = useState({
    displayCalendar: false,
    inputValue: "",
    anchorEl: null,
    fromDate: new Date(),
    toDate: new Date(),
  });

  useEffect(() => {
    setDateRange({
      fromDate: state.fromDate,
      toDate: state.toDate,
    });
  }, [setDateRange, state.fromDate, state.toDate]);

  const updateState = (newState: any) => {
    setState({
      ...state,
      ...newState,
    });
  };

  const onAdornmentClick = (e: any) => {
    updateState({ displayCalendar: true, anchorEl: e.currentTarget });
  };

  const onInputChange = (e: any) => {
    const inputValue = e.target.value;
    const { fromDate, toDate } = processInputValue(inputValue);

    updateState({ inputValue, fromDate, toDate });
  };

  const onPopoverClose = () => {
    if (onCalendarClose) {
      onCalendarClose();
    }
    updateState({ displayCalendar: false, anchorEl: null });
  };

  const onSelectDateRanges = ({ selection }: any) => {
    let { startDate, endDate } = selection;

    startDate = moment(startDate);
    startDate = startDate.isValid() ? startDate.toDate() : undefined;

    endDate = moment(endDate);
    endDate = endDate.isValid() ? endDate.toDate() : undefined;

    let inputValue = "";
    if (startDate) inputValue += moment(startDate).format(dateFormat);
    if (endDate) inputValue += " - " + moment(endDate).format(dateFormat);

    updateState({ fromDate: startDate, toDate: endDate, inputValue });
  };

  const processInputValue = (value: string) => {
    let [fromDate, toDate] = value.split("-").map((elm: any) => elm.trim());

    fromDate = moment(fromDate, dateFormat);
    fromDate = fromDate.isValid() ? fromDate.toDate() : undefined;

    toDate = moment(toDate, dateFormat);
    toDate = toDate.isValid() ? toDate.toDate() : undefined;

    return { fromDate, toDate };
  };

  return (
    <>
      <TextField
        label={label || ""}
        fullWidth={true}
        value={state.inputValue}
        onClick={onAdornmentClick}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={onAdornmentClick}>
                <DateRangeIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        onChange={onInputChange}
      />
      <Popover
        open={state.displayCalendar}
        anchorEl={state.anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={onPopoverClose}
      >
        <DateRange
          ranges={[
            {
              startDate: state.fromDate,
              endDate: state.toDate,
              key: "selection",
            },
          ]}
          onChange={onSelectDateRanges}
          maxDate={new Date()}
          showMonthAndYearPickers={true}
          moveRangeOnFirstSelection={false}
          showDateDisplay={false}
          direction="horizontal"
        />
      </Popover>
    </>
  );
};

export default DateRangePicker;
