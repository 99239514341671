import { styled } from "@mui/material/styles";
import { Box, Drawer, IconButton } from "@mui/material";
import useResponsive from "hooks/useResponsive";
import Logo from "components/Core/Logo";
import Menu from "components/Menu";
import menuOptions from "layouts/MenuOptions";
// import { useCurrentState } from "context/global";
// import { useCurrentUser } from "context/user";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useState } from "react";
import { MiniLogo } from "components/Core/Logo/Logo";

const DRAWER_WIDTH = 240;
const MINI_DRAWER_WIDTH = 80;

const RootStyle = styled("div")<{ miniDrawer: boolean }>(({ theme, miniDrawer }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    width: miniDrawer ? MINI_DRAWER_WIDTH : DRAWER_WIDTH,
  },
}));

type DashboardSidebarProps = {
  isOpenSidebar: boolean;
  onCloseSidebar: () => void;
};

const DashboardSidebar = ({ isOpenSidebar, onCloseSidebar }: DashboardSidebarProps) => {
  const isDesktop = useResponsive("up", "lg");
  const [miniDrawer, setMiniDrawer] = useState(false);

  const renderContent = (
    <>
      <Box sx={{ px: 2, py: 3, display: "inline-flex" }}>
        {miniDrawer ? <MiniLogo /> : <Logo width={180} />}
      </Box>
      <Menu navConfig={menuOptions()} miniDrawer={miniDrawer} />
    </>
  );

  const minimiseSidebarButton = (
    <IconButton
      sx={{
        top: "70px",
        left: miniDrawer ? "64px" : "222px",
        zIndex: 1201,
        borderRadius: "50%",
        padding: "4px",
        border: "1px dashed rgba(145, 158, 171, 0.2)",
        backgroundColor: "rgba(255, 255, 255, 0.48)",
      }}
      onClick={() => setMiniDrawer(!miniDrawer)}
    >
      {miniDrawer ? <KeyboardArrowRightIcon /> : <KeyboardArrowLeftIcon />}
    </IconButton>
  );

  return (
    <RootStyle miniDrawer={miniDrawer}>
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <>
          {minimiseSidebarButton}
          <Drawer
            open
            variant="persistent"
            PaperProps={{
              sx: {
                width: miniDrawer ? MINI_DRAWER_WIDTH : DRAWER_WIDTH,
                bgcolor: "background.default",
                borderRightStyle: "dashed",
              },
            }}
          >
            {renderContent}
          </Drawer>
        </>
      )}
    </RootStyle>
  );
};

export default DashboardSidebar;
