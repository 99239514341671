import { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import Confirm from "components/Core/Confirm";
import { removeVideoFromChannel } from "api/api";
import MoreOptions from "components/Core/MoreOptions";
import { useDialog } from "context/dialog";

type MoreOptionsProps = {
  channelId: string;
  videoId: string;
  videoTitle: string;
};

const VideoTableAction = ({ channelId, videoId, videoTitle }: MoreOptionsProps) => {
  const { alertSuccess, alertError } = useDialog();
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const removeVideo = async () => {
    try {
      setShowDeleteConfirm(false);
      await removeVideoFromChannel(channelId, videoId);
      alertSuccess("Removed video from channel successfully");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (err) {
      alertError("Something went wrong!");
    }
  };

  return (
    <>
      <Confirm
        isOpen={showDeleteConfirm}
        title={`Are you sure you want to remove ${videoTitle} video from channel?`}
        confirmBtnText={"Yes"}
        cancelBtnText={"No"}
        confirmfn={() => removeVideo()}
        cancelfn={() => setShowDeleteConfirm(false)}
      />

      <MoreOptions
        options={[
          {
            label: "Remove from Channel",
            icon: <DeleteIcon width={24} height={24} />,
            onClick: () => setShowDeleteConfirm(true),
          },
        ]}
      />
    </>
  );
};

export default VideoTableAction;
