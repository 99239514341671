export const QUICK_FILTER_OPTIONS = [
  { label: "Last 7 days", value: 7 },
  { label: "Last 30 days", value: 30 },
  { label: "Last 60 days", value: 60 },
  { label: "Last 90 days", value: 90 },
  { label: "All Time", value: 0 },
];

export const VIDEO_TYPES = [
  { label: "Short Video", value: "short_video" },
  { label: "Long Video", value: "long_video" },
  { label: "Series", value: "series" },
];

export const POLL_TYPES = [
  { label: "Single Answer", value: "single" },
  { label: "Multiple Answer", value: "multiple" },
];

export const MEDICAL_DESIGNATIONS = [
  { label: "M.D.", value: "M.D." },
  { label: "D.O.", value: "D.O." },
  { label: "N.P.", value: "N.P." },
  { label: "P.A.", value: "P.A." },
  { label: "R.N.", value: "R.N." },
  { label: "PharmD.", value: "PharmD." },
  { label: "None of The Above", value: "None of The Above" },
];

export const STATUS_OPTIONS = [
  { label: "Approved", value: "SUBSCRIBED" },
  { label: "Rejected", value: "REJECTED" },
  { label: "Unsubscribed", value: "UNSUBSCRIBED" },
  { label: "Pending", value: "PENDING" },
  { label: "New", value: "NEW" },
];

export const ONBOARDING_STATUS_OPTIONS = [
  { label: "Customization link", value: "customization_link" },
  { label: "Customization set", value: "customization_set" },
  { label: "Watched first video", value: "watched_video" },
];
