import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import General from "./General";
import Tag from "./Tag";
import Clinics from "./Clinics";
import Channels from "./Channels";
import { Tab, TabContext, TabList, TabPanel } from "components/Core/Tab/Tab";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import SettingsIcon from "@mui/icons-material/Settings";
import ApartmentIcon from "@mui/icons-material/Apartment";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import useQuery from "hooks/useQuery";
import MovieIcon from "@mui/icons-material/Movie";
import WelcomeVideos from "./WelcomeVideos";
import { Container } from "@mui/material";
import EngineeringRoundedIcon from "@mui/icons-material/EngineeringRounded";
import Api from "./Api";

const Settings = () => {
  const { hash, updateUri } = useQuery();

  const [tabValue, setTabValue] = useState("1");
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    let hashVal = "";
    switch (newValue) {
      case "2":
        hashVal = "#welcomevideos";
        break;
      case "3":
        hashVal = "#channels";
        break;
      case "4":
        hashVal = "#tags";
        break;
      case "5":
        hashVal = "#clinics";
        break;
      case "6":
        hashVal = "#api";
        break;
    }
    updateUri(hashVal);
    setTabValue(newValue);
  };

  useEffect(() => {
    switch (hash) {
      case "#welcomevideos":
        setTabValue("2");
        break;
      case "#channels":
        setTabValue("3");
        break;
      case "#tags":
        setTabValue("4");
        break;
      case "#clinics":
        setTabValue("5");
        break;
      case "#api":
        setTabValue("6");
        break;
      default:
        setTabValue("1");
        break;
    }
  }, [hash]);

  return (
    <Container>
      <Typography
        component="h1"
        variant="h5"
        fontWeight={600}
        textTransform={"capitalize"}
        mb={5}
      >
        Settings
      </Typography>
      <TabContext value={tabValue}>
        <TabList onChange={handleChange}>
          <Tab icon={<SettingsIcon />} iconPosition="start" label="General" value="1" />
          <Tab
            icon={<MovieIcon />}
            iconPosition="start"
            label="Welcome Videos"
            value="2"
          />
          <Tab
            icon={<WorkspacesIcon />}
            iconPosition="start"
            label="Channels"
            value="3"
          />
          <Tab icon={<LocalOfferIcon />} iconPosition="start" label="Tags" value="4" />
          <Tab icon={<ApartmentIcon />} iconPosition="start" label="Clinics" value="5" />
          <Tab
            icon={<EngineeringRoundedIcon />}
            iconPosition="start"
            label="Api"
            value="6"
          />
        </TabList>
        <TabPanel value="1">
          <General />
        </TabPanel>
        <TabPanel value="2">
          <WelcomeVideos />
        </TabPanel>
        <TabPanel value="3">
          <Channels />
        </TabPanel>
        <TabPanel value="4">
          <Tag />
        </TabPanel>
        <TabPanel value="5">
          <Clinics />
        </TabPanel>
        <TabPanel value="6">
          <Api />
        </TabPanel>
      </TabContext>
    </Container>
  );
};

export default Settings;
