import { Card, CardContent, Stack, Typography } from "@mui/material";

type Props = {
  title: string;
  stats: number;
  helperText: string;
};

const GridCard = ({ title, stats, helperText }: Props) => {
  return (
    <Card sx={{ height: "100%" }}>
      <CardContent>
        <Typography fontSize={18} mb={2}>
          {title}
        </Typography>
        <Stack direction="row" alignItems="center" mb={2} spacing={1}>
          <Typography fontSize={14} fontWeight={"bold"}>
            {stats}
          </Typography>
          <Typography fontSize={14}>{helperText}</Typography>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default GridCard;
