import { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Button,
  Card,
  Container,
  Radio,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import Scrollbar from "components/Core/Scrollbar";
import { AccountType } from "typings/account";
import { getAccounts } from "api/api";

import useQuery from "hooks/useQuery";
import { getUserAttributes, refreshToken, updateUserAttributes } from "helpers/auth";
import pages from "enums/pages";
import AddIcon from "@mui/icons-material/Add";
import { StyledTableCell } from "components/Core/Table/Table.styles";
import { useDialog } from "context/dialog";
import { formatDate } from "helpers/utils";

const TABLE_HEAD = [
  { id: "currentAccount", label: "Current Account" },
  { id: "name", label: "Name" },
  { id: "slug", label: "Slug" },
  { id: "email", label: "Email" },
  { id: "createdDate", label: "Created Date" },
];

const Account = () => {
  const limit = 20;
  const { showPageLoading } = useDialog();
  const { params, updateQueryParams } = useQuery();
  const [page, setPage] = useState(+params.page || 0);

  const [totalCount, setTotalCount] = useState(0);

  const [accounts, setAccounts] = useState<AccountType[]>([]);
  const [selectedAccount, setSelectedAccount] = useState("");

  const handleChangePage = (event: any, newPage: number) => {
    updateQueryParams({ page: newPage, limit: limit });
    setPage(newPage);
  };

  useEffect(() => {
    showPageLoading(true);
    getAccounts({}, limit, page)
      .then((data: any) => {
        setAccounts(data.rows);
        setTotalCount(data.count);
      })
      .finally(() => {
        showPageLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    getUserAttributes()
      .then((data: any) => {
        setSelectedAccount(data["custom:account_id"]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleAccountSelection = async (id: string) => {
    showPageLoading(true);
    setSelectedAccount(id);
    try {
      await updateUserAttributes({
        "custom:account_id": id,
      }); // refresh token automatically
      refreshToken();
    } catch (err) {
      console.log(err);
    }
    showPageLoading(false);
  };

  return (
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Accounts
        </Typography>
        <Button
          variant="contained"
          component={RouterLink}
          to={pages.ADMIN_ADD_ACCOUNT_PAGE}
          startIcon={<AddIcon />}
          size="large"
        >
          New Account
        </Button>
      </Stack>

      <Card>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <TableHead>
                <TableRow>
                  {TABLE_HEAD.map((headCell) => (
                    <StyledTableCell key={headCell.id}>{headCell.label}</StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {accounts.map((row: AccountType) => {
                  let { id, name, slug, email, created_at } = row;

                  return (
                    <TableRow hover key={id} tabIndex={-1}>
                      <TableCell>
                        <Radio
                          checked={selectedAccount === id}
                          onChange={(e) => handleAccountSelection(e.target.value)}
                          value={id}
                          name="accountSelection"
                        />
                      </TableCell>
                      <TableCell>{name}</TableCell>
                      <TableCell>{slug}</TableCell>
                      <TableCell>{email}</TableCell>
                      <TableCell>{formatDate(created_at)}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[10]}
            component="div"
            count={totalCount}
            rowsPerPage={limit}
            page={page}
            onPageChange={handleChangePage}
          />
        </Scrollbar>
      </Card>
    </Container>
  );
};

export default Account;
