import { Outlet, useRoutes } from "react-router-dom";
import BasicLayout from "./layouts/BasicLayout";
import DashboardLayout from "layouts/Dashboard";

import pages from "enums/pages";

import Login from "./pages/auth/login";
import Signup from "./pages/auth/signup";
import ForgotPassword from "./pages/auth/forgotPassword";
import ConfirmUser from "./pages/auth/confirm";
import Dashboard from "./pages/dashboard/dashboard";
import AdminDashboard from "./pages/dashboard/adminDashboard";
import SubscriberDashboard from "./pages/dashboard/subscriberDashboard";
import VideoDashboard from "./pages/dashboard/videoDashboard";
import BusinessDashboard from "./pages/dashboard/businessDashboard";
import Settings from "./pages/settings/settings";
import Channel from "./pages/channel/channel";
import VideoLibrary from "./pages/videolibrary/videoLibrary";
import EditVideo from "./pages/videolibrary/video";
import ChannelVideos from "./pages/channel/videos";
import Users from "./pages/users/users";
import Profile from "./pages/profile/profile";
import VideosReports from "./pages/reports/reports";
import SubscribersReports from "./pages/reports/subscribersReports";
import Account from "./pages/account/account";
import AddAccount from "./pages/account/add";
import Subscribers from "./pages/subscribers/subscribers";
import Subscriber from "./pages/subscribers/subscriber";
import Messages from "./pages/messages/messages";
import Clinic from "./pages/clinic/clinic";
import Forbidden from "./pages/forbidden/forbidden";

const Router = () => {
  return useRoutes([
    // admin login
    { path: pages.ADMIN_LOGIN_PAGE, element: <Login /> },
    {
      path: pages.HOME_PAGE,
      element: <BasicLayout />,
      children: [
        // unauthorized
        {
          path: pages.SLUG,
          element: <Outlet />,
          children: [
            { path: pages.LOGIN_PAGE, element: <Login /> },
            { path: pages.SIGNUP_PAGE, element: <Signup /> },
            { path: pages.FORGOT_PASSWORD_PAGE, element: <ForgotPassword /> },
            { path: pages.CONFIRM_ACCOUNT_PAGE, element: <ConfirmUser /> },
          ],
        },
        // authorized
        {
          path: pages.HOME_PAGE,
          element: <DashboardLayout />,
          children: [
            { path: pages.BUSINESS_DASHBOARD_PAGE, element: <BusinessDashboard /> },
            { path: pages.DASHBOARD_PAGE, element: <Dashboard /> },
            { path: pages.VIDEOS_LIBRARY_PAGE, element: <VideoLibrary /> },
            { path: pages.VIDEOS_PAGE, element: <EditVideo /> },
            { path: pages.CHANNELS_PAGE, element: <Channel /> },
            { path: pages.CHANNELS_VIDEOS_PAGE, element: <ChannelVideos /> },
            { path: pages.USERS_PAGE, element: <Users /> },
            { path: pages.SUBSCRIBERS_PAGE, element: <Subscribers /> },
            { path: pages.SUBSCRIBER_PAGE, element: <Subscriber /> },
            { path: pages.PROFILE_PAGE, element: <Profile /> },
            { path: pages.VIDEOS_REPORTS_PAGE, element: <VideosReports /> },
            { path: pages.SUBSCRIBERS_REPORTS_PAGE, element: <SubscribersReports /> },
            { path: pages.SETTINGS_PAGE, element: <Settings /> },
            { path: pages.ADMIN_ACCOUNTS_PAGE, element: <Account /> },
            { path: pages.ADMIN_DASHBOARD_PAGE, element: <AdminDashboard /> },
            { path: pages.SUBSCRIBER_DASHBOARD_PAGE, element: <SubscriberDashboard /> },
            { path: pages.VIDEO_DASHBOARD_PAGE, element: <VideoDashboard /> },
            { path: pages.ADMIN_ADD_ACCOUNT_PAGE, element: <AddAccount /> },
            { path: pages.ADMIN_MESSAGES_PAGE, element: <Messages admin={true} /> },
            { path: pages.MESSAGES_PAGE, element: <Messages /> },
            { path: pages.OTHER_CLINICS, element: <Clinic /> },
            { path: pages.PAGE_403, element: <Forbidden /> },
          ],
        },
      ],
    },
  ]);
};

export default Router;
