import { useCallback, useEffect, useState } from "react";

import {
  Card,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import Scrollbar from "components/Core/Scrollbar";
import { ClinicType } from "typings/clinic";
import { getClinics } from "api/api";
import useQuery from "hooks/useQuery";
import { StyledTableCell } from "components/Core/Table/Table.styles";
import MoreOptions from "components/Core/MoreOptions";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ReplayIcon from "@mui/icons-material/Replay";
import AddIcon from "@mui/icons-material/Add";

import DeleteClinic from "./DeleteClinic";
import AddUpdateClinic from "./AddUpdateClinic";
import CreateAccount from "./CreateAccount";
import AssignClinicToAccount from "./AssignClinicToAccount";
import { useDialog } from "context/dialog";
import { formatDate } from "helpers/utils";

const TABLE_HEAD = [
  { id: "name", label: "Name" },
  { id: "createdDate", label: "Created Date" },
  { id: "action", label: "" },
];

const Account = () => {
  const limit = 20;
  const { showPageLoading } = useDialog();
  const { params, updateQueryParams } = useQuery();

  const [page, setPage] = useState(+params.page || 0);
  const [totalCount, setTotalCount] = useState(0);

  const [clinics, setClinics] = useState<ClinicType[]>([]);
  const [currentClinic, setCurrentClinic] = useState<ClinicType>();

  const [showCreateAccountModal, setShowCreateAccountModal] = useState(false);
  const [showAssignAccountModal, setShowAssignAccountModal] = useState(false);
  const [showEditClinicModal, setShowEditClinicModal] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const handleChangePage = (event: any, newPage: number) => {
    updateQueryParams({ page: newPage, limit: limit });
    setPage(newPage);
  };

  const getData = useCallback(() => {
    showPageLoading(true);
    getClinics(limit, page, true)
      .then((data: any) => {
        setClinics(data.rows);
        setTotalCount(data.count);
      })
      .finally(() => {
        showPageLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          New Clinics
        </Typography>
      </Stack>

      {currentClinic && (
        <>
          <AssignClinicToAccount
            openModal={showAssignAccountModal}
            setOpenModal={setShowAssignAccountModal}
            clinic={currentClinic}
            refreshList={getData}
          />
          <CreateAccount
            openModal={showCreateAccountModal}
            setOpenModal={setShowCreateAccountModal}
            clinic={currentClinic}
            refreshList={getData}
          />
          <DeleteClinic
            openModal={showDeleteConfirm}
            setOpenModal={setShowDeleteConfirm}
            clinic={currentClinic}
            refreshList={getData}
          />
        </>
      )}

      <AddUpdateClinic
        openModal={showEditClinicModal}
        setOpenModal={setShowEditClinicModal}
        clinic={currentClinic}
        refreshList={getData}
        showAddButton={false}
      />

      <Card>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <TableHead>
                <TableRow>
                  {TABLE_HEAD.map((headCell) => (
                    <StyledTableCell key={headCell.id}>{headCell.label}</StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {clinics.map((row: ClinicType) => {
                  let { id, name, description, created_at } = row;

                  return (
                    <TableRow hover key={id} tabIndex={-1}>
                      <TableCell>{name}</TableCell>
                      <TableCell>{formatDate(created_at)}</TableCell>
                      <TableCell align="right">
                        <MoreOptions
                          options={[
                            {
                              label: "Create Account",
                              icon: <AddIcon />,
                              onClick: () => {
                                setCurrentClinic({
                                  id,
                                  name,
                                  description,
                                });
                                setShowCreateAccountModal(true);
                              },
                            },
                            {
                              label: "Assign to Account",
                              icon: <ReplayIcon />,
                              onClick: () => {
                                setCurrentClinic({
                                  id,
                                  name,
                                  description,
                                });
                                setShowAssignAccountModal(true);
                              },
                            },
                            {
                              label: "Edit",
                              icon: <EditIcon />,
                              onClick: () => {
                                setCurrentClinic({
                                  id,
                                  name,
                                  description,
                                });
                                setShowEditClinicModal(true);
                              },
                            },
                            {
                              label: "Delete",
                              icon: <DeleteIcon />,
                              onClick: () => {
                                setCurrentClinic({
                                  id,
                                  name,
                                  description,
                                });
                                setShowDeleteConfirm(true);
                              },
                            },
                          ]}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[10]}
            component="div"
            count={totalCount}
            rowsPerPage={limit}
            page={page}
            onPageChange={handleChangePage}
          />
        </Scrollbar>
      </Card>
    </Container>
  );
};

export default Account;
