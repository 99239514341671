import { useCallback, useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Rating,
  Stack,
  Typography,
} from "@mui/material";
import { getVideoMetrics } from "api/api";
import Select from "components/Core/Inputs/Select";
import moment from "moment";
import GridCard from "./Card";
import VideoViewChart from "./VideoViewChart";
import { secondsToTime } from "helpers/utils";
import { useParams } from "react-router-dom";
import useQuery from "hooks/useQuery";
import Header from "./DashboardHeader";
import PollCharts from "./PollCharts";
import { QUICK_FILTER_OPTIONS, VIDEO_TYPES } from "enums/dropdown";
import { BEGINNING_DATE } from "enums/common";
import { useDialog } from "context/dialog";

type ChartProps = { labels: string[]; data: number[] };

const VideoDashboard = () => {
  const { showPageLoading } = useDialog();
  const [quickFilterVal, setQuickFilter] = useState<number>(
    QUICK_FILTER_OPTIONS[0].value,
  );

  const [videoType, setVideoType] = useState<string>(VIDEO_TYPES[0].value);

  const [totalVideosSent, setTotalVideosSent] = useState(0);
  const [totalViews, setTotalViews] = useState(0);
  const [totalPlayTime, setTotalPlayTime] = useState(0);
  const [avgPlaytime, setAvgPlaytime] = useState(0);
  const [openedVideosCount, setOpenedVideosCount] = useState(0);
  const [videosViewsOverTime, setVideosViewsOverTime] = useState<ChartProps>();
  const [polls, setPolls] = useState<any>();

  const { videoId } = useParams();
  const { params: queryParams } = useQuery();
  const { name } = queryParams;
  const rc = +queryParams.rc || 0;
  const rate = +queryParams.rate || 0;

  const getVideoMetricsData = useCallback(async () => {
    showPageLoading(true);
    try {
      const fromDate = quickFilterVal
        ? moment().subtract(quickFilterVal, "days").format("YYYY-MM-DD")
        : moment(BEGINNING_DATE).format("YYYY-MM-DD");
      const toDate = moment().format("YYYY-MM-DD");

      if (fromDate && toDate && videoId && videoType) {
        const data: any = await getVideoMetrics(videoId, videoType, fromDate, toDate);

        setTotalVideosSent(data.totalVideosSent);
        setTotalViews(data.videoViewCount);
        setTotalPlayTime(data.totalPlaytime);
        setAvgPlaytime(data.avgPlaytime);
        setOpenedVideosCount(data.totalOpenedVideos);
        setPolls(data.polls);

        let days: string[] = [];
        let viewsCounts: number[] = [];

        if (quickFilterVal) {
          days = [...Array(quickFilterVal)].map((_, i) =>
            moment().subtract(i, "days").format("YYYY-MM-DD"),
          );
          viewsCounts = [...Array(quickFilterVal)].map(() => 0);
          data.videosViewedOverTime.map(
            (r: any) => (viewsCounts[days.indexOf(r.day)] = r.count),
          );
        } else {
          days = data.videosViewedOverTime.map((r: any) => r.day);
          viewsCounts = data.videosViewedOverTime.map((r: any) => r.count);
        }

        setVideosViewsOverTime({
          labels: days,
          data: viewsCounts,
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      showPageLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quickFilterVal, videoId, videoType]);

  useEffect(() => {
    getVideoMetricsData();
  }, [getVideoMetricsData]);

  const gridData = [
    {
      title: "Videos Sent",
      helperText: "videos Sent",
      stats: totalVideosSent,
    },
    {
      title: "Open",
      helperText: "videos opened",
      stats: openedVideosCount,
    },
    {
      title: "Full View",
      helperText: "full views",
      stats: totalViews,
    },
    {
      title: "Playtime",
      helperText: "",
      stats: secondsToTime(totalPlayTime) || "0s",
    },
    {
      title: "Avg playtime",
      helperText: "",
      stats: secondsToTime(avgPlaytime) || "0s",
    },
  ];

  return (
    <Container maxWidth={"xl"}>
      <Header>
        <Stack direction={"row"}>
          <Typography variant="h4" mr={1}>
            {name}
          </Typography>
          <Rating name="video-rating" value={rate} readOnly />
          {rc > 0 && <Typography mr={1}>({rc})</Typography>}
        </Stack>
      </Header>

      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
        <Box>
          <Typography variant="h5">
            {QUICK_FILTER_OPTIONS.find((o) => o.value === quickFilterVal)?.label}
          </Typography>
        </Box>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          width={410}
        >
          <Box width={200}>
            <Select
              id="quickFilter"
              label="Quick Filter"
              name="quickFilter"
              options={QUICK_FILTER_OPTIONS}
              value={quickFilterVal}
              onChange={(val: number) => setQuickFilter(val)}
            />
          </Box>
          <Box width={200}>
            <Select
              id="videoType"
              label="Video Type"
              name="videoType"
              options={VIDEO_TYPES}
              value={videoType}
              onChange={(val: string) => setVideoType(val)}
            />
          </Box>
        </Stack>
      </Stack>

      <Grid container spacing={2} mb={3}>
        {gridData.map((d: any, index: number) => (
          <Grid key={index} item xs={12} sm={6} md={4} lg={4} xl>
            <GridCard title={d.title} helperText={d.helperText} stats={d.stats} />
          </Grid>
        ))}
      </Grid>

      {polls?.map((p: any, index: number) => {
        const data = [...Array(p.options.length || 0)].map(() => 0);
        p?.stats?.pollStat.map(
          (r: any) => (data[p.options.indexOf(r.option)] = parseInt(r.count)),
        );

        const totalResponse = data.reduce((a: number, b: number) => a + b, 0);
        const totalSent = p?.stats?.sentCount;
        const displayCount = p?.stats?.displayCount;
        return (
          <PollCharts
            key={index}
            title={p.question}
            labels={p.options || []}
            data={data}
            totalResponse={totalResponse}
            totalSent={totalSent}
            displayCount={displayCount}
          />
        );
      })}

      <Card>
        <CardContent>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent={"space-between"}
            mb={2}
            spacing={1}
          >
            <Typography fontSize={18} mb={3}>
              Videos Viewed Over Time
            </Typography>
          </Stack>
          <VideoViewChart
            height={"70%"}
            labels={videosViewsOverTime?.labels || []}
            data={videosViewsOverTime?.data || []}
          />
        </CardContent>
      </Card>
    </Container>
  );
};

export default VideoDashboard;
