import AddIcon from "@mui/icons-material/Add";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Stack, Typography } from "@mui/material";
import InputField from "components/Core/Inputs";
import { useFormik } from "formik";
import { useState } from "react";
import Modal from "components/Core/Modal";
import { useDialog } from "context/dialog";
import VALIDATION from "helpers/validation_schemas";
import { addDoctor } from "api/api";

type Props = {
  refreshDoctors: (doctorId: string) => void;
};

const AddDoctor = ({ refreshDoctors }: Props) => {
  const { alertSuccess, alertError } = useDialog();

  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      mobile: "",
      email: "",
    },
    enableReinitialize: true,
    validationSchema: VALIDATION.doctor_schema,
    onSubmit: async (values, { resetForm }) => {
      try {
        setIsLoading(true);
        const res: any = await addDoctor(values);
        const doctorId = res?.doctor?.id;
        alertSuccess("Added doctor successfully.");
        refreshDoctors(doctorId);
        resetForm();
        setOpenModal(false);
      } catch (err: any) {
        alertError(err.message);
      } finally {
        setIsLoading(false);
      }
    },
  });

  return (
    <>
      <Button
        variant="contained"
        onClick={() => setOpenModal(true)}
        sx={{ whiteSpace: "nowrap", height: 48, width: 160 }}
      >
        <AddIcon />
        Add Doctor
      </Button>

      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Typography variant="h4" gutterBottom align="center">
          Doctor
        </Typography>

        <Box component="form" onSubmit={formik.handleSubmit}>
          <Stack gap={2}>
            <InputField
              id="firstName"
              name="firstName"
              label="First Name"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.firstName && Boolean(formik.errors.firstName)}
              helperText={formik.touched.firstName && formik.errors.firstName}
              fullWidth
              labelInsideInputField
            />

            <InputField
              id="lastName"
              name="lastName"
              label="Last Name"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
              fullWidth
              labelInsideInputField
            />

            <InputField
              id="mobile"
              name="mobile"
              label="Mobile"
              value={formik.values.mobile}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.mobile && Boolean(formik.errors.mobile)}
              helperText={formik.touched.mobile && formik.errors.mobile}
              fullWidth
              labelInsideInputField
            />

            <InputField
              id="email"
              name="email"
              label="Email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              fullWidth
              labelInsideInputField
            />

            <LoadingButton
              fullWidth
              type="submit"
              size="large"
              variant="contained"
              loading={isLoading}
            >
              Save
            </LoadingButton>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

export default AddDoctor;
