import { Tab as MTab } from "@mui/material";
import {
  TabContext as MTabContext,
  TabList as MTabList,
  TabPanel as MTabPanel,
} from "@mui/lab";
import styled from "styled-components";

export const Tab = styled(MTab)`
  min-height: 48px;
  margin-right: 40px;
  padding: 0;
`;

export const TabContext = styled(MTabContext)``;

export const TabList = styled(MTabList)`
  margin-bottom: 40px;
`;

export const TabPanel = styled(MTabPanel)`
  padding: 0;
`;
