import { useCallback, useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Card,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
  createFilterOptions,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import MultiSelect from "components/Core/Inputs/MultiSelect";

import Table from "components/Core/Table/Table";

import {
  SortingState,
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import InputField from "components/Core/Inputs/Input";
import { OptionType } from "components/Core/Inputs/MultiSelect";
import Scrollbar from "components/Core/Scrollbar/Scrollbar";
import Select from "components/Core/Inputs/Select";
import DateRangePickr, {
  RangeType,
} from "components/Core/DateRangePicker/DateRangePicker";

import { getChannels, getReports, getVideoSeries } from "api/api";
import DownloadIcon from "@mui/icons-material/Download";

import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { LoadingButton } from "@mui/lab";
import { CSVLink } from "react-csv";
import { VIDEO_TYPES } from "enums/dropdown";
import { formatDate } from "helpers/utils";
import moment from "moment";

const columnHelper = createColumnHelper<any>();

const VideosReports = () => {
  const [loading, setLoading] = useState(false);
  const [reportType, setReportType] = useState("video");
  const [dateRange, setDateRange] = useState<RangeType>({
    fromDate: undefined,
    toDate: undefined,
  });
  const [videoType, setVideoType] = useState<string>(VIDEO_TYPES[0].value);
  const [seriesInput, setSeriesInput] = useState<string>("");
  const [seriesId, setSeriesId] = useState<string>("");
  const [videoSeries, setVideoSeries] = useState<OptionType[]>([]);
  const [channelsDropdown, setChannelsDropdown] = useState<any[]>([
    { label: "All", value: "all" },
  ]);
  const [selectedChannel, setSelectedChannel] = useState<string>("all");

  const [reportData, setReportData] = useState<any>([]);
  const [totalcount, setTotalCount] = useState<number>(0);

  const [tableColumns, setTableColumns] = useState<string[]>([
    "video",
    "account",
    "video_sent",
    "apps",
    "physicians",
    "open_count",
    "full_view",
    "partial_view",
    "scheduled_on",
    "poll_response_count",
    "click_to_long_video",
  ]);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const seriesFilter = createFilterOptions<any>();

  const [sorting, setSorting] = useState<SortingState>([]);

  useEffect(() => {
    if (videoType === "series") {
      setReportType("series");
      setSeriesInput("");
      fetchSeriesId();
    } else if (videoType === "short_video") {
      setReportType("video");
      setSeriesId("");
    } else if (videoType === "long_video") {
      setReportType("video");
      setSeriesId("");
    }
  }, [videoType]);

  const fetchSeriesId = async () => {
    const videoSeriesRes: any = await getVideoSeries();
    setVideoSeries(
      videoSeriesRes.videoSeries.map((row: any) => ({
        label: row.name,
        value: row.id,
      })),
    );
  };

  const handleSeriesChange = (event: any, newValue: any) => {
    setSeriesInput(newValue?.inputValue || newValue?.label || "");
    setSeriesId(newValue?.value || "");
  };

  const filterSeries = (options: any[], params: any) => {
    const filtered = seriesFilter(options, params);
    const { inputValue } = params;
    const isExisting = options.some((option) => inputValue === option.label);
    if (inputValue !== "" && !isExisting) {
      filtered.push({
        inputValue,
        label: `Add "${inputValue}" Series`,
      });
    }
    return filtered;
  };

  const fetchData = async () => {
    if (reportType && dateRange.fromDate && dateRange.toDate) {
      setLoading(true);

      const fromDate = moment(dateRange.fromDate).format("YYYY-MM-DD");
      const toDate = moment(dateRange.toDate).format("YYYY-MM-DD");

      const data: any = await getReports(
        reportType,
        selectedChannel,
        fromDate,
        toDate,
        videoType === "short_video",
        seriesId,
      );

      data.videoReport.forEach((row: any) => {
        // Create a map of the existing responses
        const existingResponses: { [key: string]: number } = {}; // Add index signature
        row.pollStats?.forEach((item: any) => {
          item.responses.forEach(
            ({ response, count }: { response: string; count: number }) => {
              existingResponses[response] = count;
            },
          );

          // Iterate over the options and ensure each one is in the responses array
          item.options.forEach((option: any) => {
            if (!Object.prototype.hasOwnProperty.call(existingResponses, option)) {
              item.responses.push({ response: option, count: 0 });
            }
          });
        });
      });

      setReportData(data.videoReport);

      setTotalCount(data.videoReport.length);

      setLoading(false);
    }
  };

  const loadFilterData = useCallback(async () => {
    const channels: any = await getChannels(100, 0);
    setChannelsDropdown([
      { label: "All", value: "all" },
      ...channels.rows.map((c: any) => ({ label: c.name, value: c.id })),
    ]);
  }, []);

  useEffect(() => {
    loadFilterData();
  }, [loadFilterData]);

  const exportPDF = () => {
    const doc = new jsPDF();
    autoTable(doc, { html: "#report" });
    doc.save(`videos-report-${moment().format("YYYYMMDD")}.pdf`);
  };

  const columns: any = [
    columnHelper.accessor("Expand", {
      id: "expand",
      header: "",
      cell: ({ row }: any) => {
        if (videoType !== "series") {
          return null;
        }
        return (
          <IconButton
            onClick={() => row.toggleExpanded()}
            size="small"
            style={{ padding: 0 }}
          >
            {row.getIsExpanded() ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        );
      },
    }),
    columnHelper.accessor("Video", {
      id: "video",
      header: "Title",
      cell: ({ row }: any) => {
        return row.original.title;
      },
    }),
    columnHelper.accessor("Account", {
      id: "account",
      header: "Account",
      cell: ({ row }: any) => {
        return row.original.account_name;
      },
    }),
    columnHelper.accessor("Video Sent", {
      id: "video_sent",
      header: "Video Sent",
      cell: ({ row }: any) => {
        return row.original.video_sent;
      },
    }),
    columnHelper.accessor("Apps", {
      id: "apps",
      header: "Apps Sent",
      cell: ({ row }: any) => {
        return row.original.apps;
      },
    }),
    columnHelper.accessor("Physicians", {
      id: "physicians",
      header: "Physicians Sent",
      cell: ({ row }: any) => {
        return row.original.physicians;
      },
    }),
    columnHelper.accessor("Open Count", {
      id: "open_count",
      header: "Open Count",
      cell: ({ row }: any) => {
        return row.original.open_count;
      },
    }),
    columnHelper.accessor("Full Viewed", {
      id: "full_view",
      header: "Full Viewed",
      cell: ({ row }: any) => {
        return row.original.full_view;
      },
    }),
    columnHelper.accessor("Partial Viewed", {
      id: "partial_view",
      header: "Partial Viewed",
      cell: ({ row }: any) => {
        return row.original.partial_view;
      },
    }),
    columnHelper.accessor("Scheduled For", {
      id: "scheduled_on",
      header: "Scheduled For",
      cell: ({ row }: any) => {
        return row.original.scheduled_on;
      },
    }),
    columnHelper.accessor("Poll Response Count", {
      id: "poll_response_count",
      header: "Poll Response Count",
      cell: ({ row }: any) => {
        return row.original.poll_response_count;
      },
    }),
  ];

  if (videoType === "series") {
    columns.push(
      columnHelper.accessor("Click to Long Video", {
        id: "click_to_long_video",
        header: "Click to Long Video",
        cell: ({ row }: any) => {
          return row.original.click_to_long || 0;
        },
      }),
    );
  }

  const visibleColums = columns
    .filter((col: any) => col.header && typeof col.header === "string")
    .map((col: any) => ({ value: col.id, label: col.header }));

  const [columnVisibility, setColumnVisibility] = useState<any>({
    video: true,
    account: true,
    video_sent: true,
    apps: true,
    physicians: true,
    open_count: true,
    full_view: true,
    partial_view: true,
    scheduled_on: true,
    poll_response_count: true,
    click_to_long_video: true,
  });

  useEffect(() => {
    setColumnVisibility((prevVisibility: any) => {
      const newVisibility = { ...prevVisibility };
      Object.keys(newVisibility).forEach((key) => {
        newVisibility[key] = tableColumns.includes(key);
      });
      return newVisibility;
    });
  }, [tableColumns]);

  const table = useReactTable({
    data: reportData,
    columns,
    state: { sorting, columnVisibility },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Container maxWidth={"xl"}>
      <Stack mb={2}>
        <Typography variant="h4">Video Reports</Typography>
      </Stack>

      <Card>
        <Stack direction={"row"} justifyContent={"space-between"} p={2}>
          <Stack direction={"row"} gap={2}>
            {/* <Box width={200}>
              <Select
                label="Report"
                value={reportType}
                options={[{ label: "Video", value: "video" }]}
                onChange={(val) => setReportType(val)}
              />
            </Box> */}

            {/* {reportType && ( */}
            <Box width={200}>
              <Select
                id="channel"
                label="Channel"
                name="channel"
                options={channelsDropdown}
                value={selectedChannel}
                onChange={(val: string) => setSelectedChannel(val)}
              />
            </Box>
            <Box width={200}>
              <Select
                id="videoType"
                label="Video Type"
                name="videoType"
                options={VIDEO_TYPES}
                value={videoType}
                onChange={(val: string) => setVideoType(val)}
              />
            </Box>
            {/* )} */}

            <Stack direction={"row"} gap={2} alignItems={"center"}>
              {videoType === "series" ? (
                <Box width={200}>
                  <Autocomplete
                    id="video-series-dropdown"
                    freeSolo
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    options={videoSeries}
                    value={seriesInput}
                    onChange={handleSeriesChange}
                    filterOptions={filterSeries}
                    getOptionLabel={(option: any) => {
                      if (typeof option === "string") {
                        return option;
                      }
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      return option.label;
                    }}
                    renderOption={(props, option) => (
                      <li key={"series-option-" + option.id} {...props}>
                        {option.label}
                      </li>
                    )}
                    renderInput={(params) => <InputField {...params} />}
                  />
                </Box>
              ) : (
                <Box>
                  <DateRangePickr label="Dates" setDateRange={setDateRange} />
                </Box>
              )}
              <Box>
                <LoadingButton
                  size="large"
                  variant="contained"
                  onClick={fetchData}
                  loading={loading}
                >
                  Get Report
                </LoadingButton>
              </Box>
            </Stack>
          </Stack>

          <Box width={200} pl={2}>
            <MultiSelect
              id={"tableColumns"}
              labelId={"tableColumns"}
              label={"Edit Columns"}
              options={visibleColums}
              selectValue={tableColumns}
              setSelectValue={(values) => {
                setTableColumns(values);
              }}
              placeholder="Edit Columns"
              showChips={false}
              sortSelected={false}
            />
          </Box>

          <Box>
            <IconButton
              onClick={(event: any) => setAnchorEl(event.currentTarget)}
              disabled={!reportData?.length}
            >
              <DownloadIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem onClick={exportPDF}>Download PDF</MenuItem>
              <MenuItem>
                <CSVLink
                  data={reportData.map((row: any) => ({
                    Video: row.title,
                    Account: row.account_name,
                    "Video Sent": row.video_sent,
                    "Apps Sent": row.apps,
                    "Physicians Sent": row.physicians,
                    "Open Count": row.open_count,
                    Playtime: row.playtime,
                    "Full viewed": row.full_view,
                    "Partial viewed": row.partial_view,
                    "Scheduled For": row.scheduled_on
                      ? formatDate(row.scheduled_on, true)
                      : "-",
                    "Poll Response Count": row.poll_response_count,
                  }))}
                  filename={`videos-report-${moment().format("YYYYMMDD")}.csv`}
                  style={{ textDecoration: "none", color: "black" }}
                >
                  Download CSV
                </CSVLink>
              </MenuItem>
            </Menu>
          </Box>
        </Stack>

        <Scrollbar>
          <Table table={table} totalCount={totalcount} rowsPerPage={100} />
        </Scrollbar>
      </Card>
    </Container>
  );
};

export default VideosReports;
