import Resizer from "react-image-file-resizer";
import { fileUploadUrl, uploadVideoUsingS3SignedUrl } from "api/api";

export const resizeThumbnail = (file: File) =>
  new Promise((resolve, reject) => {
    try {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        if (img.width >= 600) {
          const ratio = img.height / img.width;
          const newWidth = 600;
          const newHeight = ratio * 600;
          Resizer.imageFileResizer(
            file,
            newWidth,
            newHeight,
            "jpg",
            20,
            0,
            (file) => resolve(file),
            "file",
          );
        } else {
          Resizer.imageFileResizer(
            file,
            img.width,
            img.height,
            "jpg",
            20,
            0,
            (file) => resolve(file),
            "file",
          );
        }
      };
    } catch (err) {
      reject(err);
    }
  });

export const uploadImage = async (
  file: File,
  filenname: string | undefined,
  dir: string,
) => {
  const resizedFile = (await resizeThumbnail(file)) as File;

  const extension = resizedFile.name.split(".").pop();
  const type = resizedFile.type;
  const fname = `${filenname}.${extension}` || resizedFile.name;

  const { uploadUrl, url } = (await fileUploadUrl(fname, type, dir)) as {
    url: string;
    uploadUrl: string;
  };
  await uploadVideoUsingS3SignedUrl(uploadUrl, resizedFile, type);
  return url;
};
