import { Container, Stack, Typography } from "@mui/material";

import SubscribersReportsCard from "./SubscribersReportsCard";

const SubscribersReports = () => {
  return (
    <Container maxWidth={"xl"}>
      <Stack mb={2}>
        <Typography variant="h4">Subscribers Reports</Typography>
      </Stack>

      <SubscribersReportsCard />
    </Container>
  );
};

export default SubscribersReports;
