import { Box, Container, Typography } from "@mui/material";

const Forbidden = () => {
  return (
    <Container>
      <Box textAlign={"center"} my={2}>
        <Typography variant="h3" paragraph>
          No permission
        </Typography>

        <Typography sx={{ color: "text.secondary" }}>
          {`The page you're trying access has restricted access. Please refer to your system
        administrator`}
        </Typography>

        <Box my={5}>
          <Typography variant="h1">403</Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default Forbidden;
