import { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { useFormik } from "formik";

import { Box, Button, Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import AddIcon from "@mui/icons-material/Add";

import Modal from "components/Core/Modal";
import InputField from "components/Core/Inputs/Input";
import VALIDATION from "helpers/validation_schemas";
import { upsertTag } from "api/api";
import { TagType } from "typings/tag";
import { useDialog } from "context/dialog";

type Props = {
  openModal: boolean;
  setOpenModal: (x: boolean) => void;
  tag?: TagType;
  refreshList: () => void;
};

const AddUpdateTag = ({ tag, openModal, setOpenModal, refreshList }: Props) => {
  const [isNew, setIsNew] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { alertSuccess, alertError } = useDialog();

  const initValues: TagType = {
    id: "",
    tag: "",
  };
  const [initialValues, setInitialValues] = useState<TagType>(initValues);

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: VALIDATION.tag_schema,
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      try {
        await upsertTag(values.id || uuid(), { tag: values.tag });
        alertSuccess("Tag added successfully.");

        refreshList();
        setOpenModal(false);
      } catch (err: any) {
        alertError(err.message);
      }
      setIsLoading(false);
      resetForm();
    },
  });

  useEffect(() => {
    if (isNew || !tag) {
      setInitialValues(initValues);
    } else if (tag) {
      setInitialValues({
        id: tag.id,
        tag: tag.tag,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tag, isNew]);

  useEffect(() => {
    if (!openModal) {
      formik.resetForm();
      setIsNew(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal]);

  return (
    <>
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Typography variant="h4" gutterBottom align="center">
          Tag
        </Typography>

        <Box component="form" onSubmit={formik.handleSubmit}>
          <Stack gap={2}>
            <InputField
              id="tag"
              name="tag"
              label="Tag Name"
              value={formik.values.tag}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.tag && Boolean(formik.errors.tag)}
              helperText={formik.touched.tag && formik.errors.tag}
              fullWidth
              labelInsideInputField
            />

            <LoadingButton
              fullWidth
              type="submit"
              size="large"
              variant="contained"
              loading={isLoading}
            >
              Save
            </LoadingButton>
          </Stack>
        </Box>
      </Modal>

      <Box p={2} textAlign={"right"}>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => {
            setIsNew(true);
            setOpenModal(true);
          }}
          size="large"
        >
          New Tag
        </Button>
      </Box>
    </>
  );
};

export default AddUpdateTag;
