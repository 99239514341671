import { useEffect } from "react";
import {
  Navigate,
  Outlet,
  matchRoutes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { getTheme } from "api/api";
import { useCurrentState } from "context/global";
import { isSessionValid } from "helpers/auth";

import pages from "enums/pages";
import { permissions } from "enums/permissions";
import { ROLES } from "enums/common";

const BasicLayout = () => {
  const { slug } = useParams();
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();

  const routes = Object.values(pages).map((route) => ({ path: route }));
  const matches = matchRoutes(routes, location);

  const { globalState, setGlobalState } = useCurrentState();

  const isLoggedIn = !!isSessionValid();

  useEffect(() => {
    if (slug && slug !== "admin") {
      getTheme(slug)
        .then((res: any) => {
          const state: any = {};
          if (res.primaryColor) state.primaryColor = res.primaryColor;
          if (res.secondaryColor) state.secondaryColor = res.secondaryColor;
          if (res.logo) state.logo = res.logo;
          setGlobalState(state);
        })
        .catch((err) => {
          console.log(err);
          navigate(pages.ADMIN_LOGIN_PAGE);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);

  useEffect(() => {
    if (isLoggedIn && (globalState?.isSuperAdmin || globalState?.role)) {
      if (pathname === pages.HOME_PAGE) {
        if (globalState?.role === ROLES.LITE_CHANNEL_ADMIN) {
          navigate(pages.CHANNELS_PAGE);
        } else {
          navigate(pages.DASHBOARD_PAGE);
        }
      } else {
        const route = matches && matches[0].route.path;
        if (
          route &&
          !globalState?.isSuperAdmin &&
          !permissions[route]?.includes(globalState?.role || "")
        ) {
          navigate(pages.PAGE_403);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalState, isLoggedIn, pathname]);

  if (!isLoggedIn) {
    if (slug) {
      const pathArray = pathname.replace(/^\/|\/$/g, "").split("/");
      if (pathArray.length === 1) {
        return <Navigate to={`/${slug}/login`} />;
      }
    } else {
      return (
        <Navigate
          to={pages.ADMIN_LOGIN_PAGE}
          state={{ from: location, search: location.search }}
        />
      );
    }
  }

  return <Outlet />;
};

export default BasicLayout;
