import { Card, CardContent, Typography } from "@mui/material";

import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  registerables as registerablesJS,
} from "chart.js";
import { Pie } from "react-chartjs-2";
import { useCurrentState } from "context/global";

ChartJS.register(...registerablesJS);
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
);

type Props = {
  labels: string[];
  data: number[];
};

const TopVideosChart = ({ labels, data }: Props) => {
  const { globalState } = useCurrentState();
  return (
    <Card sx={{ height: "100%" }}>
      <CardContent>
        <Typography fontSize={18} mb={3}>
          Subscribers by Account
        </Typography>
        <Pie
          data={{
            labels: labels,
            datasets: [
              {
                label: "",
                backgroundColor: [
                  globalState?.primaryColor,
                  globalState?.secondaryColor,
                  "#EAC435",
                  "#345995",
                  "#03CEA4",
                  "#FB4D3D",
                  "#CA1551",
                  "#EEF36A",
                  "#3F3047",
                  "#9BC995",
                  "#98B9AB",
                  "#FE7F2D",
                ],
                data: data,
              },
            ],
          }}
          options={{
            responsive: true,
            plugins: {
              legend: {
                position: "bottom",
                align: "center",
                labels: {
                  usePointStyle: true,
                },
              },
            },
          }}
        />
      </CardContent>
    </Card>
  );
};

export default TopVideosChart;
