import { RefObject, useEffect, useRef, useState } from "react";
import { ColorChangeHandler, SketchPicker } from "react-color";
import FormControl from "@mui/material/FormControl";
import { TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";

type Props = {
  id?: string;
  name?: string;
  label: string;
  value: string;
  onChange: ColorChangeHandler;
};

const ColorPicker = ({ id, name, label, value, onChange }: Props) => {
  const [showPicker, setShowPicker] = useState(false);
  const [color, setColor] = useState("");

  const pickerRef: RefObject<HTMLInputElement> = useRef(null);

  const handleClickOutside = (event: any) => {
    if (pickerRef.current && !pickerRef.current.contains(event.target)) {
      setShowPicker(false);
    }
  };

  useEffect(() => {
    setColor(value);
  }, [value]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <FormControl ref={pickerRef} margin="normal" fullWidth sx={{ m: 0 }}>
      <TextField
        id={id}
        name={name}
        label={label}
        onFocus={() => setShowPicker(true)}
        value={color.toUpperCase()}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <div
                style={{
                  width: "80px",
                  height: "32px",
                  background: color,
                }}
                onClick={() => setShowPicker(true)}
              />
            </InputAdornment>
          ),
        }}
      />
      {showPicker && (
        <div style={{ position: "absolute", zIndex: "2", right: 0, top: "58px" }}>
          <SketchPicker color={color} onChange={onChange} />
        </div>
      )}
    </FormControl>
  );
};

export default ColorPicker;
