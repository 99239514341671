import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  registerables as registerablesJS,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import { useCurrentState } from "context/global";
import { Card, CardContent, Grid, Stack, Typography } from "@mui/material";
import { Pie } from "react-chartjs-2";
import { Box } from "@mui/system";
import { lineBreak } from "helpers/utils";

ChartJS.register(...registerablesJS);
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
);

type Props = {
  title: string;
  labels: string[];
  data: number[];
  totalSent: number;
  totalResponse: number;
  displayCount: number;
  width?: string;
  height?: string;
};

const PollCharts = ({
  title,
  labels,
  data,
  totalResponse,
  totalSent,
  displayCount,
  width,
  height,
}: Props) => {
  const { globalState } = useCurrentState();
  const pieData = data.map((d: number) => Math.round((d * 100) / totalResponse));
  return (
    <Box mb={3}>
      <Card sx={{ height: "100%" }}>
        <CardContent>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={3}
          >
            <Typography fontSize={18}>{title}</Typography>
            <Typography fontSize={18}>
              {"Sent: " +
                totalSent +
                " Responded: " +
                totalResponse +
                " Poll Display Count: " +
                displayCount}
            </Typography>
          </Stack>

          <Grid container spacing={2}>
            <Grid item xs={12} md={8} mb={3}>
              <Chart
                type="bar"
                width={width}
                height={height}
                options={{
                  indexAxis: "x",
                  responsive: true,
                  scales: {
                    x: {
                      stacked: true,
                      grid: {
                        drawBorder: false,
                        display: false,
                      },
                      ticks: {
                        callback: function (this, value, index) {
                          const label = this.getLabelForValue(index);
                          return label.length > 10
                            ? lineBreak(label, 10)[0] + "..."
                            : label;
                        },
                      },
                    },
                    y: {
                      stacked: true,
                      grid: {
                        drawBorder: false,
                        display: false,
                      },
                    },
                  },
                  plugins: {
                    legend: {
                      display: false,
                    },
                    tooltip: {
                      callbacks: {
                        title: (tooltip) => lineBreak(tooltip[0].label, 60),
                      },
                    },
                  },
                }}
                data={{
                  labels: labels,
                  datasets: [
                    {
                      type: "bar",
                      label: "Views",
                      data: data,
                      borderColor: globalState?.primaryColor,
                      backgroundColor: globalState?.primaryColor,
                      barThickness: 14,
                      borderRadius: 14,
                    },
                  ],
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Pie
                data={{
                  labels: labels,
                  datasets: [
                    {
                      label: "",
                      backgroundColor: [
                        globalState?.primaryColor,
                        globalState?.secondaryColor,
                        "#3cba9f",
                        "#e8c3b9",
                      ],
                      data: pieData,
                    },
                  ],
                }}
                options={{
                  responsive: true,
                  plugins: {
                    legend: {
                      position: "bottom",
                      align: "center",
                      labels: {
                        usePointStyle: true,
                      },
                    },
                    tooltip: {
                      callbacks: {
                        title: (tooltip) => lineBreak(tooltip[0].label, 40),
                        label: (context) => " " + context.formattedValue + "%",
                      },
                    },
                  },
                }}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

export default PollCharts;
