import React, { useEffect, useState } from "react";
import AddEditSubscriber from "./AddEditSubscriber";
import VideoLogs from "./VideoLogs";
import { Tab, TabContext, TabList, TabPanel } from "components/Core/Tab/Tab";
import { Container, Typography } from "@mui/material";

import PersonIcon from "@mui/icons-material/Person";
import ViewListIcon from "@mui/icons-material/ViewList";

import useQuery from "hooks/useQuery";
import { useCurrentState } from "context/global";
import { ROLES } from "enums/common";

const Settings = () => {
  const { params, hash, updateUri } = useQuery();
  const isNew = params.new === "true";

  const { globalState } = useCurrentState();
  const role = globalState?.role;

  const [tabValue, setTabValue] = useState("1");
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    let hashVal = "";
    switch (newValue) {
      case "2":
        hashVal = "#logs";
        break;
    }
    updateUri(hashVal);
    setTabValue(newValue);
  };

  useEffect(() => {
    switch (hash) {
      case "#logs":
        setTabValue("2");
        break;
      default:
        setTabValue("1");
        break;
    }
  }, [hash]);

  return (
    <Container maxWidth={"xl"}>
      <Typography variant="h4" mb={5}>
        Subscriber
      </Typography>
      <TabContext value={tabValue}>
        <TabList onChange={handleChange}>
          <Tab iconPosition="start" label="Subscriber" icon={<PersonIcon />} value="1" />
          {!isNew && role !== ROLES.LITE_CHANNEL_ADMIN && (
            <Tab
              iconPosition="start"
              label="Video Logs"
              icon={<ViewListIcon />}
              value="2"
            />
          )}
        </TabList>
        <TabPanel value="1">
          <AddEditSubscriber />
        </TabPanel>

        {role !== ROLES.LITE_CHANNEL_ADMIN && (
          <TabPanel value="2">
            <VideoLogs />
          </TabPanel>
        )}
      </TabContext>
    </Container>
  );
};

export default Settings;
