import { Box, Typography } from "@mui/material";
import { useCurrentState } from "context/global";

type Props = {
  width?: number;
  height?: number;
};

export const Logo = ({ width, height }: Props) => {
  const { globalState } = useCurrentState();

  return (
    <img
      src={
        globalState?.logo ||
        "https://ideologyhealth.com/wp-content/uploads/2022/03/ideo-main-logo.png"
      }
      style={{ mixBlendMode: "multiply" }}
      width={width}
      height={height}
    />
  );
};

export const MiniLogo = () => {
  const { globalState } = useCurrentState();
  const abbreviatedName = globalState?.accountName
    ?.match(/\b\w/g)
    ?.join("")
    ?.toUpperCase();

  return (
    <Box
      sx={{
        background: globalState?.primaryColor,
        borderRadius: "8px",
        padding: "10px",
        marginBottom: "12px",
      }}
    >
      <Typography
        color={"#fff"}
        fontWeight={"600"}
        fontSize={"24px"}
        lineHeight={"initial"}
        fontFamily={"monospace"}
      >
        {abbreviatedName}
      </Typography>
    </Box>
  );
};

export const SbLogo = ({ width, height }: Props) => {
  return (
    <img
      src={"https://ideologyhealth.com/wp-content/uploads/2023/06/Sound-Bites.png"}
      style={{ mixBlendMode: "multiply" }}
      width={width}
      height={height}
    />
  );
};

export const CombinedLogo = () => (
  <Box>
    <Box display={"flex"} flexDirection={"row"} justifyContent={"center"}>
      <Logo width={130} />
    </Box>

    <Box display={"flex"} flexDirection={"row"} justifyContent={"center"}>
      <SbLogo width={250} />
    </Box>
  </Box>
);

export default Logo;
