import { useFormik } from "formik";
import { v4 as uuid } from "uuid";
import { Box, Stack, Typography } from "@mui/material";

import InputField from "components/Core/Inputs/Input";
import Select from "components/Core/Inputs/Select";
import Modal from "components/Core/Modal";
import { LoadingButton } from "@mui/lab";
import { useEffect, useState } from "react";
import { Defaults, Message } from "./Message";
import { useDialog } from "context/dialog";
import { saveMessage } from "api/api";
import VALIDATION from "helpers/validation_schemas";

type Props = {
  admin?: boolean;
  openModal: boolean;
  setOpenModal: (x: boolean) => void;
  message?: Message | null;
  refreshList: () => void;
  defaults: Defaults;
};

const AddEditMessage = ({
  admin = false,
  message,
  openModal,
  defaults,
  setOpenModal,
  refreshList,
}: Props) => {
  const { alertSuccess, alertError } = useDialog();
  const [allowMessageEdit, setAllowMessageEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      message: message?.message || "",
      type: message?.type || "",
    },
    enableReinitialize: true,
    validationSchema: VALIDATION.add_message,
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      try {
        await saveMessage(message?.id || uuid(), values.type, values.message, admin);

        alertSuccess("Messages saved successfully");
        refreshList();
        setOpenModal(false);
      } catch (err: any) {
        alertError(err.message);
      }
      setIsLoading(false);
      resetForm();
    },
  });

  useEffect(() => {
    if (!openModal) {
      formik.resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal]);

  const messageTypeOptions =
    (defaults &&
      Object.values(defaults).map((message) => ({
        label: message.label,
        value: message.type,
      }))) ||
    [];

  return (
    <Modal open={openModal} onClose={() => setOpenModal(false)}>
      <Typography variant="h4" gutterBottom align="center">
        {message ? "Update Message" : "New Message"}
      </Typography>

      <Box component="form" onSubmit={formik.handleSubmit}>
        <Stack gap={2}>
          <Select
            label="Message Type"
            id="type"
            name="type"
            options={messageTypeOptions}
            value={formik.values.type}
            onChange={(val: string) => {
              formik.setFieldValue("type", val);
              formik.setFieldValue(
                "message",
                (defaults && defaults[val].defaultMessage) || "",
              );
            }}
            error={formik.touched.type && Boolean(formik.errors.type)}
            helperText={formik.touched.type && formik.errors.type}
          />

          <InputField
            label="Message"
            id="message"
            name="message"
            value={formik.values.message}
            onChange={(event: any) => {
              if (allowMessageEdit) {
                formik.handleChange(event);
              }
            }}
            onKeyDown={(event: any) => {
              setAllowMessageEdit(true);
              const key = event.key;
              if (key === "Backspace" || key === "Delete") {
                const cursorAt = event.target.selectionStart;
                const placeholders =
                  (defaults && defaults[formik.values.type].placeholders) || [];

                placeholders.map((placeholder: string) => {
                  const startPos = event.target.value.indexOf(placeholder);
                  const endPos = startPos + placeholder.length;
                  if (startPos <= cursorAt && endPos >= cursorAt) {
                    setAllowMessageEdit(false);
                  }
                });
              }
            }}
            error={formik.touched.message && Boolean(formik.errors.message)}
            helperText={formik.touched.message && formik.errors.message}
            rows={10}
            fullWidth
            multiline
            labelInsideInputField
          />

          <LoadingButton
            type="submit"
            size="large"
            variant="contained"
            loading={isLoading}
          >
            Save Messages
          </LoadingButton>
        </Stack>
      </Box>
    </Modal>
  );
};

export default AddEditMessage;
